import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Login from './Components/Login/Login';
import CountrySelection from './Components/CountrySelection/CountrySelection';
import LayoutComponent from './Components/Layout/Layout';
import PageContextProvider, { PageContext } from './Hooks/ContextProvider';
import CreateRegistration from './Components/CreateRegistration/CreateRegistration';
import 'devextreme/dist/css/dx.light.css';
import Home from './Components/Home/Home';
import RegistrationComponent from './Components/Registration/RegistrationComponent/RegistrationComponent';
import PendingApprove from './Components/Panel/PendingApprove';
import RecoveryPassword from './Components/RecoverPassword/RecoveryPassword';
import Cities from './Components/Cities/Cities';
import FillContract, { IContract } from './Components/Contract/FillContract';

import { useContext, useEffect, useState } from 'react';
import { storeModel } from './Models/store';
import { axiosInstance } from './commons/services/axiosConfig';


function App() {
  const [store, setStore]: any = useState<storeModel>();
  const {
    userData,
  } = useContext(PageContext);

  const [contracts, setContracts]: any = useState<IContract[]>();

  useEffect(() => {
    if (window.location.pathname !== '/PendingApprove') {
      getStoreData();
    }
  }, []);

  const getStoreData = () => {

    if (userData) {
      axiosInstance.get<storeModel>(`/Stores/${userData.storeID}`).then(res => {
        if (res.data.stateId == 1 || res.data.stateId == 4) {
          window.location.href = "/PendingApprove";
        }
        if (res.data.stateId == 3) {
          window.location.href = "/updateProfileStore";
        }
        else if (res.data.contractStores?.length > 0 && res.data.contractStores?.find(x => x.status == 0)) {

            setContracts(res.data.contractStores)
            window.location.href = "/contracts";

          } else {
            setStore(res.data);
          }
      })
    }
  }
  return (
    <div className="App">
      <PageContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/storeHome" element={<LayoutComponent />} />
            <Route path="/login" element={<Login />} />
            <Route path="/countrySelection" element={<CountrySelection />} />
            <Route path="/createRegistration" element={<CreateRegistration />} />
            <Route path="/registration" element={<RegistrationComponent />} />
            <Route path="/PendingApprove" element={<PendingApprove titulo={"Tu tienda está en proceso de aprobación!"} subtitulo={"Te notificaremos por correo electrónico cuando tu tienda esté habilitada."} />} />
            <Route path="/updateProfileStore" element={<PendingApprove titulo={'Actualiza tus datos'} subtitulo={'Debes actulizar tus datos para habilitar la tienda'} />} />
            <Route path="/Contracts" element={<FillContract />} />
            <Route path="/updateuserpass" element={<RecoveryPassword />} />
            <Route path="/Cities" element={<Cities />} />
          </Routes>
        </BrowserRouter>
      </PageContextProvider>
    </div>
  );
}

export default App;
