import {
  EyeOutlined,
  IssuesCloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Row,
  Input,
  message,
  Popconfirm,
  Select,
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { city } from "../../Models/city.model";
import { StoreClient } from "../../Models/StoreClient";
import { deliveryNews } from "../../Models/SuperAdmin/deliveryNews.model";
import { IModalBillNewsProps } from "./IModalBillNewsProps";
import { email } from "../../Models/email.model";
import "./ModalBillNews.css";
import { BillData } from "../../Models/Bill";
import ModalNoveltyHoko from "../ModalNoveltyHoko/ModalNoveltyHoko";

const { TextArea } = Input;
const { Option } = Select;
export interface deliveryNewType {
  id: number;
  description: string;
}

export interface enviaNews {
  aclaracion: string;
  cod_novedad: string;
  codUsrMod: string;
  comentario: string;
  contacto: string;
  diasEnNovedad: string;
  fecGestion: string;
  fecNovedad: string;
  fechaSolucion: string;
  mcaEstado: string;
  novedad: string;
  responsable_solucion: string;
  tipoOrigennovedad: string;
  response: string;
}

const ModalBillNews = (props: IModalBillNewsProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [deliveryNewsTypesList, setDeliveryNewsTypesList] = useState<
    deliveryNewType[]
  >([]);
  const [datosGuia, setDatosGuia] = useState({
    name: "",
    adress: "",
    city: "",
    phone: "",
  });
  const [enviaDeliveryNews, setEnviaDeliveryNews] = useState<enviaNews[]>([]);
  const [solutionText, setSolutionText] = useState<string>("");
  const [solutionType, setSolutionType] = useState<number>(0);
  const [citiesListFiltered, setCitiesListFiltered] = useState<Array<city>>([]);
  const [citiesList, setCitiesList] = useState<Array<city>>([]);
  const [initialized, setInitialized] = useState(false);
  const [enviaNews, setEnviaNews] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [deliveryNews, setDeliveryNews] = useState<deliveryNews[]>([]);
  const [storeClient, setStoreClient] = useState<StoreClient>({
    adress_field_1: "",
    adress_field_2: "",
    adress_field_3: "",
    adress_field_4: "",
    adress_field_5: "",
    adress_field_6: "",
    adress_field_7: "",
    adress_field_8: "",
    adress_field_9: "",
    adress_field_10: "",
    adress_field_11: "",
    adress_field_12: "",
    adress_indications: "",
    building: "",
    carrera: "",
    cellphone: "",
    cityId: 6834,
    created_at: new Date(),
    created_by: parseInt(localStorage.userID),
    email: "",
    gender: "",
    id: 0,
    identification: "",
    name: "",
    neighborhood: "",
    phone: "",
    updated_at: new Date(),
    warningComments: "",
  });

  useEffect(() => {
    getEnviaNews();
    getDeliveryNews();
    getDeliveryNewsTypes();
  }, [props.billingId]);

  useEffect(() => {
    if (!initialized) {
      getCities();
      setInitialized(true);
    }
  }, []);

  const getDeliveryNewsTypes = () => {
    axiosInstance.get<deliveryNewType[]>(`/DeliveryNewTypes`).then((res) => {
      setDeliveryNewsTypesList(res.data);
    });
  };

  const getEnviaNews = async () => {
    //Consulta guia Envia
    if (props.company_TransportId === 1) {
      axiosInstance.get(`/Bills/GetStatusDeliveryEnvia?guia=${props.guia}`).then(
        (res) => {
          let el: any = {
            name:
              res.data.nombre_destinatario.length > 25
                ? res.data.nombre_destinatario.substring(0, 25)
                : res.data.nombre_destinatario,
            adress: res.data.direccion_destinatario,
            city: res.data.ciudad_destino,
            phone: "",
          };
          if (res.data.novedad !== null) {
            setEnviaDeliveryNews(res.data.novedad);

            let codEnviaNew = res.data.novedad.map((x: any) => {
              let codeNovedad = {
                cod_novedad: x.cod_novedad,
              };
              return codeNovedad;
            });
            setEnviaNews(codEnviaNew);
          }
          setDatosGuia(el);
        }
      );
    } else {
      let res = await axiosInstance.get<BillData>(`/Bills/GetBillByGuia/${props.guia}`);
      let billData = res.data;

      const cities = await axiosInstance.get<city[]>("/Cities");
      let city = cities.data.filter(
        (x) => x.id === res.data.storeClient?.cityId
      )[0];

      let el: any = {
        name: res.data.storeClient?.name,
        adress: `${billData?.storeClient?.carrera} ${billData?.storeClient?.adress_field_1
          } 
          ${billData?.storeClient?.adress_field_2} ${billData?.storeClient?.adress_field_3
          } 
          ${billData?.storeClient?.adress_field_1 !== "" ? "#" : ""} 
          ${billData?.storeClient?.adress_field_4} ${billData?.storeClient?.adress_field_5
          } ${billData?.storeClient?.adress_field_6}
          ${billData?.storeClient?.adress_field_7 !== "" ? "-" : ""} ${billData?.storeClient?.adress_field_7
          }
          ${billData?.storeClient?.adress_field_8} ${billData?.storeClient?.adress_field_9
          }
          ${billData?.storeClient?.adress_field_10} ${billData?.storeClient?.adress_field_11
          } 
          ${billData?.storeClient?.adress_field_12}
          ${billData?.storeClient?.adress_indications}
          `,
        city: `${city?.name} - ${city?.provinceName}`,
        phone: res.data.storeClient?.cellphone,
      };
      setDatosGuia(el);
    }
  };

  const saveSolutionDeliveryNews = async (data: deliveryNews) => {
    if (solutionText) {
      data.response = `${solutionText}`;
      data.state = "Solucionada";
      if (props.company_TransportId == 5) {
        let el =
        {
          codigoOrden: props.guia,
          novedadOrden: solutionText,
          codigoUsuario: "1477"
        }
        let resp = await axiosInstance.post('/DeliveryNews/MensajeroExpressSolution', el)
      }
      axiosInstance.put(`/DeliveryNews/${data.id}`, data).then((res) => {
        enviarEmail(`${solutionText}`);
        message.success("La solución fue enviada");
        setVisible(false);
      });
    } else {
      message.warning("Indica el texto de la solución");
    }
  };

  const enviarEmail = (solution: string) => {
    let email: email = {
      subject: "Solucion Novedad",
      mailTo: "apoyocrecel2@gmail.com",
      body: `Se ha registrado una nueva solución para la guia:<b> ${props.guia}</b> <br/>
       Solución: ${solution}`,
    };
    axiosInstance.post(`/Mail`, email);
  };

  const solutionEnvia = (guia: string) => {
    setLoading(true);
    if (solutionText) {
      let el: any = {
        guia: guia,
        // essolucion: solutionType == 0 ? 0 : 1,
        essolucion: 1,
        comentario: solutionText,
        contacto: localStorage.userName.slice(0, 25),
        nuevo_Destinatario: datosGuia.name,
        nueva_Direccion: datosGuia.adress,
        nueva_Ciudad: citiesList.find(
          (city: any) => city.id === storeClient?.cityId
        )?.code,
        // nueva_Ciudad: datosGuia.city,
        nuevo_Telefono: datosGuia.phone,
        fecha_cita: "",
        tipocategoria: solutionType,
      };
      enviaDeliveryNews.map((x) => {
        if (
          x.cod_novedad === "21" ||
          x.cod_novedad === "31" ||
          x.cod_novedad === "34" ||
          x.cod_novedad === "35" ||
          x.cod_novedad === "36" ||
          x.cod_novedad === "40" ||
          x.cod_novedad === "44" ||
          el.tipocategoria === 2
        ) {
          if (el.nuevo_Destinatario === "") {
            message.warning(
              "Llene el campo de Nuevo Destinatario e intente nuevamente"
            );
            setLoading(false);
          }
          if (el.nueva_Direccion === "") {
            message.warning(
              "Llene el campo de Nueva Dirección e intente nuevamente"
            );
            setLoading(false);
          }
          if (el.nueva_Ciudad === "") {
            message.warning(
              "Llene el campo de Nueva Cuidad e intente nuevamente"
            );
            setLoading(false);
          }
          if (el.nuevo_Telefono === "") {
            message.warning("Llene el campo de Teléfono e intente nuevamente");
            setLoading(false);
          }

        }

        axiosInstance.post(`/Bills/UpdateEnviaGuia`, el)
          .then((res: any) => {
            message.success("Se ha guardado la solución correctamente");
            setLoading(false);
          })
          .catch((resp) => {
            message.error(`Error: ${resp.response.data.errorMessage}`, 8);
            setLoading(false);
          });
      });
    } else {
      message.warning("Indica el texto de la solución en el campo 'Comentario'");
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setDatosGuia((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeSolution = (text: string) => {
    if (text.length <= 255) {
      setSolutionText(text);
    }
  };

  const updateStoreClientState = (value: any, field: string) => {
    let userTemp = { ...(storeClient as any) };
    userTemp[field] = value;
    setStoreClient(userTemp);

  };

  const onSearchFilteredCities = (value: string) => {
    if (value) {
      setCitiesListFiltered(
        citiesList.filter((x: city) => x.name.toLowerCase().includes(value))
      );
    } else {
      setCitiesListFiltered(citiesList);
    }
  };

  const getCities = () => {
    axiosInstance.get(`/Cities`).then((res) => {
      setCitiesList(res.data);
      setCitiesListFiltered(res.data.filter((x: city) => x.isActived == true));
    });
  };

  const getDeliveryNews = () => {
    if (props.billingId) {
      axiosInstance.get(
        `/DeliveryNews/GetDelivery_News?billingId=${props.billingId}`
      ).then((res) => {
        setDeliveryNews(res.data);
      });
    }
  };

  const DetalBillNews = () => {
    if (props.company_TransportId == 2) {
      window.open(
        `https://web.palomamensajeria.com/DetailBill?guia=${props.guia}`,
        "_blank"
      );
    }
    else if (props.company_TransportId == 7) {
      window.open(
        `https://fenix.laarcourier.com/Tracking/Guiacompleta.aspx?guia=${props.guia}`,
        "_blank"
      );
    }
    else {
      setVisible(true);
    }
  };

  return (
    <div className={`modalBillNews`}>
      {(deliveryNews.length > 0 || enviaNews.length > 0) && (
        <Button type="primary" onClick={DetalBillNews}>
          <IssuesCloseOutlined style={{ color: "orange" }} /> Novedad
        </Button>
      )}

      <Modal
        open={visible}
        width={800}
        title={`Novedades de entrega guia: ${props.guia}`}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cerrar
          </Button>,
        ]}
      >
        <div style={{ padding: 15 }}>
          <Row>
            <Col span={12}>
              GUIA: <b>{props.guia}</b>
            </Col>
            <Col span={12}>
              Cliente: <b>{datosGuia.name}</b>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              Dirección: <b>{datosGuia.adress}</b>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              {localStorage.currency == "COP" ? (
                <>
                  Ciudad:{" "}
                  <b>
                    {" "}
                    {
                      citiesList.find(
                        (city: any) => city.id === storeClient.cityId
                      )?.name
                    }
                  </b>
                </>
              ) : (
                <>
                  Ciudad: <b>{datosGuia.city}</b>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <hr />
              {deliveryNews.map((x) => (
                <>
                  <Row>
                    <Col span={12}>
                      <p>
                        <b>Novedad:</b>{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 10,
                            color: "blue",
                          }}
                        >
                          ({moment(x.created_at).format("YYYY-MM-DD HH:mm")})
                        </span>
                        <br />
                        {/* <span style={{marginLeft:10,fontWeight:'bold'}}>{filterDeliveryNewsType(x.id)}</span>   */}
                        <br />
                        {x.description}
                      </p>
                    </Col>
                    {props.company_TransportId == 8 && <ModalNoveltyHoko billId={props.billingId} deliveryNews={x}></ModalNoveltyHoko>}
                    {props.company_TransportId !== 8 && props.company_TransportId !== 1 && (<Col span={12}>
                      <b>Solución:</b>{" "}
                      {x.response ? (
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 10,
                            color: "blue",
                          }}
                        >
                          ({moment(x.updated_at).format("YYYY-MM-DD HH:mm")})
                        </span>
                      ) : (
                        <Popconfirm
                          title="¿ Seguro de enviar este texto como solución ?"
                          okText="Sí"
                          cancelText="No"
                          onConfirm={() => saveSolutionDeliveryNews(x)}
                        >
                          <Button type="primary" style={{ float: "right" }}>
                            <SaveOutlined />
                            Guardar
                          </Button>
                        </Popconfirm>
                      )}
                      {x.response ? (
                        <TextArea rows={4} disabled={true} value={x.response} />
                      ) : (
                        <>
                          <TextArea
                            rows={3}
                            onChange={(e) => onChangeSolution(e.target.value)}
                          />
                          <div style={{ fontSize: 10, color: "grey" }}>
                            El texto no puede superar 255 caracteres (
                            {solutionText.length} / 255)
                          </div>
                        </>
                      )}
                    </Col>)}
                  </Row>
                  <hr />
                </>
              ))}
            </Col>
          </Row>

          {/*ENVIA*/}
          <Row>
            <Col span={24}>
              <hr />
              {enviaDeliveryNews.map((x) => (
                <>
                  <Row>
                    <Col span={12}>
                      <p>
                        <b>Novedad:</b>{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 10,
                            color: "blue",
                          }}
                        >
                          ({x.fecNovedad})
                        </span>
                        <br />
                        <br />
                        <p>{x.novedad}</p>
                        {x.comentario}
                      </p>
                    </Col>
                    {x.responsable_solucion !== "OP" && (
                      <Col span={12}>
                        <b>Solución:</b>{" "}
                        {x.response ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: 10,
                              color: "blue",
                            }}
                          >
                            ({moment(x.fecGestion).format("YYYY-MM-DD HH:mm")})
                          </span>
                        ) : (
                          <Popconfirm
                            title="¿ Seguro de enviar este texto como solución ?"
                            okText="Sí"
                            cancelText="No"
                            onConfirm={() => solutionEnvia(props.guia)}
                          >
                            <Button type="primary" style={{ float: "right" }} loading={loading}>
                              <SaveOutlined />
                              Guardar
                            </Button>
                          </Popconfirm>
                        )}
                        {x.response ? (
                          <TextArea
                            rows={4}
                            disabled={true}
                            value={x.response}
                          />
                        ) : (
                          <>
                            Tipo de solución:
                            <Select
                              onChange={(e: any) => setSolutionType(e)}
                              style={{ width: "100%" }}
                              defaultValue={"Volver a ofrecer"}
                            >
                              <Option key={"Volver a ofrecer"} value={0}>
                                Volver a ofrecer
                              </Option>
                              <Option key={"Devolución"} value={1}>
                                Devolución
                              </Option>
                              <Option key={"Redirección"} value={2}>
                                Redirección
                              </Option>
                            </Select>
                            <br />
                            <div>
                              Nuevo Destinatario:
                              <Input
                                value={datosGuia.name}
                                name="name"
                                onChange={(e) => handleChange(e)}
                                maxLength={25}
                                style={{ marginBottom: 10 }}
                              />
                              Nueva Dirección:
                              <Input
                                value={datosGuia.adress}
                                name="adress"
                                onChange={(e) => handleChange(e)}
                                style={{ marginBottom: 10 }}
                              />
                              Ciudad:
                              {/* <Input
                                    value={datosGuia.city}
                                    name="city"
                                    onChange={(e) => handleChange(e)}
                                    style={{ marginBottom: 10 }}
                                  /> */}
                              <Select
                                // className={styles.input}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={"Selecciona una ciudad"}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={onSearchFilteredCities}
                                onChange={(e) =>
                                  updateStoreClientState(e, "cityId")
                                }
                                // onChange={(e) => handleChange(e)}
                                notFoundContent={null}
                                value={storeClient.cityId}
                              >
                                {citiesListFiltered.map((x: city) => (
                                  <Option key={x.id} value={x.id}>
                                    {x.name} - {x.provinceName}
                                  </Option>
                                ))}
                              </Select>
                              Teléfono:
                              <Input
                                value={datosGuia.phone}
                                name="phone"
                                onChange={(e) => handleChange(e)}
                                style={{ marginBottom: 10 }}
                              />
                            </div>
                            Comentario:
                            <TextArea
                              rows={3}
                              onChange={(e) => onChangeSolution(e.target.value)}
                            />
                            <div style={{ fontSize: 10, color: "grey" }}>
                              El texto no puede superar 255 caracteres (
                              {solutionText.length} / 255)
                            </div>
                          </>
                        )}
                      </Col>
                    )}
                  </Row>
                </>
              ))}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalBillNews;
