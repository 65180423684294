import { useContext, useState } from "react";
import { axiosInstance } from "../commons/services/axiosConfig";
import { Auth } from "../Models/User.Model";
import utils from "../utils";
import { PageContext } from "./ContextProvider";

export const useLogin = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [authData, setAuthData]: any = useState<Auth>({ User: "", Password: "" });

    const {
        userData,
        setUserData
    } = useContext(PageContext);

    const updateState = (e: any) => {
        setAuthData({ ...authData, [e.target.name]: e.target.value });
    }

    const login = async () => {
        setLoading(true);
        var resp = await axiosInstance.post("/Login", authData).catch(err => { setLoading(false) });
        if (resp) {
            let userD = utils.parseJwt(resp.data);
            utils.crearCookie("auth_token", resp.data, userD.exp);
            let userDataTemp:any = {...userData};
            userDataTemp.userName = userD.nombre;
            userDataTemp.userID = userD.nameid;
            userDataTemp.storeID = userD.storeId;
            userDataTemp.email = userD.email;
            let roles = userD["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            userDataTemp.roles = roles;
            setUserData(userDataTemp);
            setLoading(false);
            localStorage.auth_token=resp.data;
            localStorage.storeID = userD.storeId;
            localStorage.userName = userD.nombre;
            window.location.href = "/countryselection";
        };
    }

    return {
        loading,
        login,
        updateState
    }
}