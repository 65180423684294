import { Button, Card, Col, Row, Statistic } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../commons/services/axiosConfig';

import { useModalSaldoTienda } from './hooks/useModalSaldoTienda';
import { IModalSaldoTiendaProps } from './IModalSaldoTiendaProps';
import './ModalSaldoTienda.css';

const ModalSaldoTienda = (props:IModalSaldoTiendaProps) => {
  const [totalWallet, setTotalWallet] = useState(0);
  const [Adelanto, setAdelanto] = useState(0);

  const closeModal = () => {
    props.setVisible(false);
  }

  const closeModalApproved = () => {
    props.setVisible(false);
    aprobarRetiro(props.dataSelected)
  }

  const closeModalRejected = () => {
    props.setVisible(false);
    rechazarRetiro(props.dataSelected)
  }


  useEffect(() => {
    getStoreWalletHistory();
  }, [props.storeId]);

  const {
    aprobarRetiro,
    rechazarRetiro
  } = useModalSaldoTienda(props.rechargePending);

  const getStoreWalletHistory = async () => {
    if (localStorage.storeID !== "") {
      let storeWallet = await axiosInstance.get(`/StoreWallet/TotalPurse?storeId=${props.storeId}`);
      let availableAdvance =  await axiosInstance.get(`/StoreWallet/TotalAvailableAdvance?storeId=${props.storeId}`);
      setAdelanto(availableAdvance.data);
      setTotalWallet(storeWallet.data);
    }
  }

  return (
    <div className={`modalSaldoTienda`}>
      <Modal
        open={props.visible}
        title={`Saldo tienda`}
        width={1000}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={[
          <Button key="back" onClick={() => closeModal()}>
            Cerrar
          </Button>
        ]}
      >
        <Row>
          <Col span={3}></Col>
          <Col span={6} className={'contenedorSaldo'}>
            <Card>
              <Statistic
                title="Saldo total"
                value={totalWallet.toFixed(2)}
                prefix={'$'}
              />
            </Card>
          </Col>
          <Col span={2}></Col>
          <Col span={6} className={'contenedorSaldo'}>
            <Card>
              <Statistic
                title="Disponible para adelantar"
                value={Adelanto.toFixed(2)}
                valueStyle={{ color: '#3f8600' }}
                prefix={'$'}
              />
            </Card>
          </Col>
          <Col span={2}></Col>
          <Col>
            <Button
              style={{ margin: 5, marginTop: 30 }} 
              type='primary'
              onClick = { () => closeModalApproved() }
            > Aprobar </Button> 
          </Col>
          <Col>
            <Button
              style={{ margin: 5, marginTop: 30 }} 
              onClick = { () => closeModalRejected() } 
              > Rechazar </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ModalSaldoTienda;
