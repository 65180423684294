import React, { useState, useEffect } from "react";
import { IRegistrationComponentProps } from "./IRegistrationComponentProps";
import "./RegistrationComponent.css";
import { Steps, Result, Button, message, Col, Row } from "antd";
import StoreRegistration from "../StoreRegistration/StoreRegistration";
import UserRegistration from "../UserRegistration/UserRegistration";
import BankRegistration from "../BankRegistration/BankRegistration";
import { user } from "../../../Models/User.Model";
import { storeModel } from "../../../Models/store";

// Images
import { store_bank } from "../../../Models/bank.model";
import { email } from "../../../Models/email.model";
import { axiosInstance } from "../../../commons/services/axiosConfig";
import { GenerateToken } from "../../../Models/GenerateToken.model";

const { Step } = Steps;

const RegistrationComponent = (props: IRegistrationComponentProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [store, setStore]: any = useState<storeModel>();
  const [user, setUser]: any = useState<user>();
  const [bank, setBank]: any = useState<store_bank>({
    id: 0,
    bankId: 0,
    bank_account: "",
    account_type: "",
    owner_account: "",
    owner_identification: "",
    StoreId: 0,
    created_at: new Date(),
    updated_at: new Date(),
    global66: "",
    payPal: "",
  });
  const [rut, setRut]: any = useState();
  const [cedula1, setCedula1]: any = useState();
  const [cedula2, setCedula2]: any = useState();
  const [contrato, setContrato]: any = useState();
  const [registerFinished, setRegisterFinished] = useState(false);
  const [storeDocuments, setStoreDocuments] = useState<StoreDocuments>({
    StoreId: 0,
    rut: "",
    cedula1: "",
    cedula2: "",
    contrato: "",
  });
  const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    validateToken();
  }, []);

  function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return "false";
  }

  const validateToken = async () => {
    const token = getQueryVariable("token");
    await axiosInstance.get<GenerateToken>(`/TempTokens/${token}`)
      .then((res) => {
        if (res.data.usedToken) {
          setTokenIsValid(false);
        } else {
          setTokenIsValid(true);
        }
      })
      .catch((err) => {
        setTokenIsValid(false);
      });
  };

  const saveRegistration = async () => {
    setLoading(true);
    store.stateId = 1;
    let storeId: number = 0;
    await axiosInstance.post(`/Stores`, store).then((res) => {
      storeId = res.data.id;
    });
    if (storeId) {
      let userSaved = await saveUser(storeId);
      let bankSaved = await saveBank(storeId);

      let saveStoreDocuments = await saveDocuments(storeId);

      const query = getQueryVariable("token");
      if (userSaved && bankSaved) {
        await axiosInstance.put(`/TempTokens/${query}`).then((res) => {
        });

        sendNotification();
        setTimeout(function () {
          setRegisterFinished(true);
          setLoading(false);
        }, 4000);
      }
    } else {
      message.warning(
        "Ya existe una tienda con este nombre, contácta con el administrador",
        8
      );
      setLoading(false);
    }
  };

  const saveUser = async (id: number): Promise<boolean> => {
    let response: boolean = false;
    user.StoreId = id;

    let arrayRoles = [];
    arrayRoles.push(3);
    user.roles = arrayRoles;

    await axiosInstance.post(`/Users`, user)
      .then((userResp) => {
        response = true;
      })
      .catch((err) => {
        message.warning(err.response.data);
        console.log(err);
        setLoading(false);
        response = false;
      });
    return response;
  };

  const saveBank = async (id: number): Promise<boolean> => {
    let response: boolean = false;
    bank.StoreId = id;
    await axiosInstance.post(`/Store_Banks`, bank)
      .then((resp) => {
        response = true;
      })
      .catch((err) => {
        message.warning(err.response.data);
        console.log(err);
        setLoading(false);
        response = false;
      });
    return response;
  };

  const saveDocuments = async (id: number) => {
    let el: any = storeDocuments;
    el.StoreId = id;
    el.rut = rut;
    el.cedula1 = cedula1;
    el.cedula2 = cedula2;
    el.contrato = contrato;
    await axiosInstance.post(`/StoreDocument`, el).then((res) => {
      return res.data;
    });
  };

  const irAlHome = () => {
    window.location.href = "/";
  };

  const sendNotification = () => {
    axiosInstance.post(`/Mail/SendDocumentsVerificationEmail?MailTo=${user.email}&UserName=${user.full_name}`);
  };

  return (
    <div className="login-container">
      <div className="blue-bar"></div>
      <Row>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <div className="login-layout">
            <div>
              <Row>
                <Col
                  xs={0}
                  sm={0}
                  md={10}
                  lg={10}
                  xl={10}
                  className="left-block container-left-registro"
                ></Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  lg={1}
                  xl={1}
                  className="right-block white-container"
                >
                  <div className="gray-bar"></div>
                </Col>
                <Col
                  xs={22}
                  sm={22}
                  md={10}
                  lg={10}
                  xl={10}
                  className="right-block white-container"
                >
                  <div className={`registrationComponent`}>
                    <Row>
                      <Col span={8}></Col>
                      <Col span={8} style={{ textAlign: "center" }}>
                        {/*  <img src={logo} style={{ width: '70%' }} /> */}
                      </Col>
                      <Col span={8}></Col>
                    </Row>
                    {tokenIsValid ? (
                      <>
                        {!registerFinished ? (
                          <>
                            <Steps current={currentStep}>
                              <Step title="Registro tienda" />
                              <Step title="Registro usuario" />
                              <Step title="Registro banco" />
                            </Steps>

                            {currentStep === 0 && (
                              <StoreRegistration
                                setStore={setStore}
                                store={store}
                                setCurrentStep={setCurrentStep}
                                setRut={setRut}
                                setCedula1={setCedula1}
                                Cedula1={cedula1}
                                setCedula2={setCedula2}
                                Cedula2={cedula2}
                                setContrato={setContrato}
                                contrato={contrato}
                                Rut={rut}
                              />
                            )}
                            {currentStep === 1 && (
                              <UserRegistration
                                user={user}
                                setUser={setUser}
                                setCurrentStep={setCurrentStep}
                              />
                            )}
                            {currentStep === 2 && (
                              <BankRegistration
                                bank={bank}
                                setBank={setBank}
                                saveRegistration={saveRegistration}
                                loading={loading}
                              />
                            )}
                          </>
                        ) : (
                          <Result
                            status="success"
                            title="Tu tienda fue registrada exitosamente!"
                            subTitle="Revisaremos la información registrada y te notificaremos por correo electrónico cuando tu tienda esté habilitada."
                            extra={[
                              <Button
                                type="primary"
                                key="console"
                                onClick={() => irAlHome()}
                              >
                                Aceptar
                              </Button>,
                            ]}
                          />
                        )}
                      </>
                    ) : (
                      <Result
                        status="warning"
                        title="El token no es válido!"
                        subTitle="Contácta con el administrador."
                        extra={[
                          <Button
                            type="primary"
                            key="console"
                            onClick={() => irAlHome()}
                          >
                            Aceptar
                          </Button>,
                        ]}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
      </Row>

      <div className="blue-bar"></div>
    </div>
  );
};

export default RegistrationComponent;

type StoreDocuments = {
  id?: number;
  StoreId: number;
  rut: string;
  cedula1: string;
  cedula2: string;
  contrato: string;
  created_at?: Date;
  updated_at?: Date;
};
