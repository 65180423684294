import { notification } from "antd";
import { useEffect, useState } from "react";
import { user } from "../../Models/SuperAdmin/users.model";
import { vw_bills } from "../../Models/SuperAdmin/vw_bills.model";
import { Vw_DispatchetBills } from "../../Models/SuperAdmin/vw_DispatchetBills.model";
import { EyeOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../commons/services/axiosConfig";
import moment from "moment";
import { CourierIds } from "./constants";

export const useSettlement = () => {
  const [loadign, setLoadign] = useState<boolean>(false);
  const [courriers, setCourriers] = useState<user[]>([]);
  const [listbills, setlistbills] = useState<vw_bills[]>([]);
  const [settledInvoices, setSettledInvoices] = useState<vw_bills[]>([]);
  const [selectedBills, setSelectedBills] = useState<vw_bills[]>([]);
  const [dispatchetBills, setDispatchetBills] = useState<Vw_DispatchetBills[]>(
    []
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedBill, setselectedBill] = useState(0);

  useEffect(() => {
    getCourriers();
    GetVw_DispatchetBillsAsync();
  }, []);

  const handleChangeCourrier = (value: number) => {
    getPendingBillsByCurriers(value);
  };

  const getPendingBillsByCurriers = (courrierId: number) => {
    setLoadign(true);
    axiosInstance.get(`/Bills/GetPendingBillsByCourrier?idCourrier=${courrierId}`).then(
      (res) => {
        if (res.data) {
          res.data.map((guia: vw_bills) => {
            if (guia.platformUse == 0 && (CourierIds.includes(guia.courierId))) {
              guia.platformUse = 2000;
              guia.collectionCommission = 1000;
              guia.delivery_value_Consolidator = guia.delivery_value - guia.platformUse - guia.collectionCommission;
            }
          });
        }
        setlistbills(res.data);
        setLoadign(false);
      }
    );
  };

  const GetPendingBillsByDate = (dates: any) => {
    setLoadign(true);
    var from = moment(dates[0]).format("YYYY-MM-DD");
    var to = moment(dates[1]).format("YYYY-MM-DD");
    axiosInstance.get(
      `/Bills/GetPendingBillsByDate?startDate=${from}&endDate=${to}`
    ).then((res) => {
      setSettledInvoices(res.data);
      setLoadign(false);
    });
  };

  const SaveData = () => {
    setLoadign(true);
    axiosInstance.post(
      "/StoreWallet/SettleInvoice",
      selectedBills.map((x) => x.guia)
    ).then((res) => {
      setLoadign(false);
      if (selectedBills.length > 0) {
        let courrierId = selectedBills[0].courierId;
        getPendingBillsByCurriers(courrierId);
        notification.success({
          message: "Las guias fueron liquidadas correctamente",
        });
      }
    });
  };

  const GetVw_DispatchetBillsAsync = () => {
    setLoadign(true);
    axiosInstance.get("/Bills/GetVw_DispatchetBillsAsync").then((res) => {
      setDispatchetBills(res.data);
      setLoadign(false);
    });
  };

  const getCourriers = () => {
    axiosInstance.get("/Users/GetCrecelCourriers").then((res) => {
      setCourriers(res.data);
    });
  };

  const selectedRows = (data: any) => {
    setSelectedBills(data.selectedRowKeys);
  };

  const showModalHandler = (billId: number) => {
    setShowModal(!showModal);
    setselectedBill(billId);
  };

  const showModalCellRender = (data: any) => {
    return <EyeOutlined onClick={() => showModalHandler(data.data.id)} />;
  };

  const PredevolutionRender = (data: any) => {
    if (data.data.status == "Predevolución") {
      return <span style={{ color: "red" }}>Pre-devolución</span>;
    }
    return null;
  };

  return {
    loadign,
    handleChangeCourrier,
    courriers,
    listbills,
    selectedRows,
    selectedBills,
    SaveData,
    GetVw_DispatchetBillsAsync,
    dispatchetBills,
    showModal,
    showModalCellRender,
    setShowModal,
    selectedBill,
    PredevolutionRender,
    GetPendingBillsByDate,
    settledInvoices,
  };
};
