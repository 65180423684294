import React, { useState, useEffect } from 'react';
import { Row, Col, Input, message, Button } from 'antd';
import { IRecoveryPassword } from './IRecoverPasswordProps';
import './RecoveryPassword.css'
import { axiosInstance } from '../../commons/services/axiosConfig';

const RecoveryPassword = (props:IRecoveryPassword) => {

    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [password, setPassword] = useState<string>()
    const [token, setToken] = useState<string>("")
    const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);
    

    useEffect(() => {
        let token:any = getQueryVariable('token');
        validateToken( token );
        setToken(token)
    }, [])

    const validateToken = (token:any) => {
        axiosInstance.get(`/ForgotPassTempTokens/${token}`).then(res => {
            setTokenIsValid(true);
        })
        .catch(error => {
            message.error('El token no es valido');
            setTokenIsValid(false);
        });
    } 

    function getQueryVariable(variable:string) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
    }
  
    const recoveryPassword = () => {
      if( isValid()) {
          let el:any = {
            newPassword: password ,
            token: token
          }
            
            axiosInstance.put('/Users/UpdateUserPass',el).then(res => {
                message.success('La Contraseña ha sido cambiada exitosamente');
                window.location.href = '/login';
            })
      }
    }

    const isValid = () => {
        let isValid = true;
        if (( confirmPassword !== password) || confirmPassword === "") {
            message.error('Las contraseñas no son iguales',3);
            isValid = false;
        } 
        return isValid;
    }

    
    return (
        <div className={`recoveryPassword`}>
        <Row>
            <Col xs={24} sm={24} md={9} lg={9} xl={9}></Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className={'contenedorPassword'}>
                <div className={'contenedorLogo'}>
                    <img alt="icono" src={'https://drophacking.blob.core.windows.net/crecel-app-assets/logoCrecel.png'} style={{width:'100%',marginTop:20,borderRadius:10}}/>
                </div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Input.Password type="password" placeholder="Nueva Contraseña" onChange={(e) => setPassword(e.target.value) } disabled={!tokenIsValid}/>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Input.Password placeholder="Confirma la contraseña" onChange={(e) => setConfirmPassword(e.target.value)} disabled={!tokenIsValid}/>
                        
                    </Col>
                    <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                </Row>
                <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Button className={'btnSavePassword'} disabled={!tokenIsValid} type="primary" onClick={()=>recoveryPassword()}>Guardar Contraseña</Button>
                </Col>
            </Col>
        </Row>
    </div>
    )
}

export default RecoveryPassword
