import { message } from "antd";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../commons/services/axiosConfig";

export const useCustomers = () => {

    const [storeClient, setStoreClient] = useState<any>({});
    const [isComment, setIsComment] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
   
    const findClient = (phone : string) => {
        if( phone !== '') {
            setLoading(true);
            axiosInstance.get(`/StoreClient?cellphone=${phone}`).then((resp : any) => {
                if( resp !== ''){
                    setStoreClient(resp.data);
                    setIsComment(true);
                    setLoading(false);
                    /*if(resp.data.warningComments !== ''){
                        setIsComment(true);
                    }else{
                        setIsComment(false);
                    }*/
                } else {
                    setLoading(false);
                    message.info('No existe ese cliente.');
                }
            })
        }
    }

    const handleChange = (value: any, field: string) => {
        let userTemp = { ...storeClient as any };
        userTemp[field] = value;

        setStoreClient(userTemp);

    }

    const handleUpdate = () => {
        if(storeClient.warningComments !== ''){
            setLoading(true);
            axiosInstance.put(`/StoreClient/${storeClient.id}`, storeClient).then(resp => {
                message.success('Se actualizó el comentario del usuario.');
                setStoreClient({});
                setIsComment(true);
                setLoading(false);
            }).catch(err => {
                message.error(`No se pudo actualizar el comentario del usuario. ${err}`);
                setLoading(false);
            })
        } else {
            message.error('Agregue un comentario al cliente.');
        }
    }

    useEffect(() => {
        setIsComment(true);
    }, [])

    return {
        findClient,
        storeClient,
        handleChange,
        handleUpdate,
        isComment,
        loading
    }
}
