import React, { useState, useEffect, useContext } from "react";
import { IStoreWalletProps } from "./IStoreWalletProps";
import "./StoreWallet.css";
import {
  Tabs,
  Col,
  Statistic,
  Row,
  Card,
  Button,
  message,
  Modal,
  InputNumber,
  Table,
  Spin,
  Select,
  Input,
  Upload,
  Result,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import utils from "../../utils";
import { body } from "./BodyEmail";
import {
  store_wallet,
  store_wallet_movement,
} from "../../Models/store_wallet.model";
import { bank, store_bank } from "../../Models/bank.model";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { email } from "../../Models/email.model";
import { storeModel } from "../../Models/store";
import ModalStoreWalletDetail from "../ModalStoreWalletDetail/ModalStoreWalletDetail";
import { usePanel } from "../../Hooks/Store/usePanel";
import { PageContext } from "../../Hooks/ContextProvider";
const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

const StoreWallet = (props: IStoreWalletProps) => {
  const [image, setImage]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [valorRecarga, setvalorRecarga] = useState(0);
  const [BancoRetiro, setBancoRetiro] = useState<string>("");
  const [totalWallet, setTotalWallet] = useState(0);
  const [Adelanto, setAdelanto] = useState(0);
  const [listBanksFiltered, setListBanksFiltered] = useState<Array<bank>>([]);
  const [listBanks, setListBanks] = useState<Array<bank>>([]);
  const [bankData, setBankData] = useState<store_bank>();
  const [sendedData, setSendedData] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [SaldoDevoluciones, setSaldoDevoluciones] = useState<number>(0);
  const [currency, setCurrency] = useState(1);
  const [IsTotalVisible, setIsTotalVisible] = useState<boolean>(true);

  useEffect(() => {
    if (localStorage.storeID !== "") {
      getBankData();
      getBanks();
      getTotalWallet();
    }
  }, []);

  const { store } = usePanel();

  const { userData } = useContext(PageContext);

  const handleSearchBank = (value: string) => {
    if (value) {
      setListBanksFiltered(
        listBanks.filter((x: bank) => x.name.toLowerCase().includes(value))
      );
    } else {
      setListBanksFiltered([]);
    }
  };

  const getBankData = async () => {
    if (userData) {
      let res = await axiosInstance.get<store_bank>(
        `/Store_Banks/${userData.storeID}`
      );
      setBankData(res.data);
    }
  };

  const getBanks = async () => {
    let res = await axiosInstance.get<bank[]>(`/Banks`);
    setListBanks(res.data);
    setListBanksFiltered(res.data);
  };

  function showConfirm() {
    confirm({
      title: "Confirma tu banco",
      icon: <ExclamationCircleOutlined />,
      content: (
        <Col xs={12} sm={12} md={24} lg={24} xl={24}>
          <b>Banco:</b>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Banco"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchBank}
            onChange={async (e: any) => {
              if (bankData !== undefined) {
                bankData.bankId = e;
                await axiosInstance.put(`/Store_Banks/${bankData.id}`, bankData);
              }
            }}
            notFoundContent={null}
          >
            {listBanksFiltered.map((x: bank) => (
              <Option key={x.id} value={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </Col>
      ),
      onOk() {
        saveData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function onchangeUploadImage(event: any) {
    setLoading(true);
    const file = event.target.files;
    uploadDocument(file);
  }

  const getTotalWallet = async () => {
    let storeWallet = await axiosInstance.get(`/StoreWallet/TotalPurse`, {
      params: { storeId: userData?.storeID, currency: localStorage.currency },
    });
    let availableAdvance = await axiosInstance.get(`/StoreWallet/TotalAvailableAdvance`, {
      params: { storeId: userData?.storeID, currency: localStorage.currency },
    });
    let returned = await axiosInstance.get(`/StoreWallet/TotalReturned`, {
      params: { storeId: userData?.storeID, currency: localStorage.currency },
    });
    setTotalWallet(storeWallet.data);
    setAdelanto(availableAdvance.data);
    setSaldoDevoluciones(returned.data);
  };

  const sendEmail = async () => {
    if (localStorage.storeID !== "") {
      let res = await axiosInstance.get<storeModel>(`/Stores/${localStorage.storeID}`);
      let data: email = {
        mailTo: "soporte@franquiciacrecel.com",
        subject: `Nueva recarga registrada por ${res.data.name}`,
        body: `La tienda ${res.data.name} ha registrado una nueva recarga en su monedero, para var los detalles, puede acceder 
          al siguiente enlace: <a href='https://www.franquiciacrecel.com/PendingRecharge'>Recargas</a>`,
      };
      axiosInstance.post(`/Mail`, data);
    }
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const propsUploadProductImage: any = {
    transformFile(file: any) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        uploadDocument(file);
        reader.readAsDataURL(file);
        reader.onload = () => {};
      });
    },
  };

  const uploadDocument = async (image: any) => {
    if (localStorage.storeID !== "") {
      let formData = new FormData();
      let guid = uuidv4();
      const myNewFile = new File([image], `${guid}.${image.type}`, {
        type: image.type,
      });
      formData.append("Attachments", myNewFile);
      formData.append("strContainerName", `comprobante-pagos`);
      formData.append("folderName", `comprobante-pagos`);
      let res = await axiosInstance.post(`/BlobStorage/UploadImageGallery`, formData);
      setImage(res.data);
      setLoading(false);
    }
  };

  const saveData = () => {
    if (localStorage.storeID !== "") {
      if (value != 0) {
        setLoading(true);
        let data: store_wallet_movement = {
          storeId: parseInt(localStorage.storeID),
          bank_transfer_receipt: image,
          created_by: parseInt(localStorage.userID),
          updated_by: parseInt(localStorage.userID),
          state: "Pendiente",
          value: value,
          currency: localStorage.currency,
          bankName: BancoRetiro,
        };

        let res = axiosInstance.post("/StoreWalletMovement", data);
        message.success("Los datos fueron enviados satisfactoriamente.", 8);
        sendEmail();
        setValue(0);
        setLoading(false);
        setSendedData(true);
      } else {
        message.warning("Indica el valor de la recarga");
      }
    }
  };

  const solicitarDinero = async () => {
    setModalVisible(false);
    if (userData?.storeID) {
      let store = await axiosInstance.get(`/Stores/${userData?.storeID}`);
      if (valorRecarga != 0) {
        setLoading(true);
        let data: store_wallet_movement = {
          storeId: userData?.storeID,
          bank_transfer_receipt: image,
          created_by: parseInt(localStorage.userID),
          updated_by: parseInt(localStorage.userID),
          state: "SRetiro",
          value: valorRecarga,
          currency: localStorage.currency,
          bankName: BancoRetiro,
        };
        axiosInstance.post(`/StoreWalletMovement`, data)
          .then((json) => {
            sendEmail();
            setValue(0);
            setLoading(false);
            setSendedData(true);
            message.success(
              "Solicitud de Retiro Procesada con Éxito! Se ha descontado del monedero el valor del retiro. En en las siguientes horas, la transferencia correspondiente se hará efectiva en tu cuenta!"
            );
            let data: email = {
              //mailTo: "gustavodavidb@gmail.com",
              mailTo: "linacalle613@gmail.com",
              subject: `Nueva solicitud de retiro de dinero`,
              body: body(store.data, valorRecarga),
            };
            axiosInstance.post(`/Mail`, data);
          })
          .catch((err) => {
            message.warning(err.response.data);
            console.log(err);
          });
        getTotalWallet();
      } else {
        message.warning("Indica el valor de la recarga");
      }
    }
  };

  const columns = [
    {
      title: "Tipo de movimiento",
      dataIndex: "movement_type",
      key: "movement_type",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (f: any, x: store_wallet) => {
        if (x.value > 0) {
          return (
            <span style={{ color: "green" }}>
              <CaretUpOutlined />{" "}
              {`${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          );
        } else {
          return (
            <span style={{ color: "red" }}>
              <CaretDownOutlined />{" "}
              {`${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          );
        }
      },
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  return (
    <div className="billing-container">
      <div className="form-container-wallet">
        <Row>
          <Col
            span={14}
            className={"background-azul"}
            style={{ textAlign: "center" }}
          >
            <img
              src="https://drophacking.blob.core.windows.net/images/Monedero/ICONO-DE-TITULO-DEL-MONEDERO.png"
              style={{ width: 100, marginTop: 100 }}
            />
            <br />
            {IsTotalVisible ? (
              <>
                <span className="sub-title-white">MONEDERO</span>
                <br />
                <span className="bold-title-white">VIRTUAL</span>
                <br />
                <br />
                <div
                  className="generic-btn"
                  style={{
                    width: 100,
                    margin: "0px auto",
                    background: "rgba(225, 225, 225,.4)",
                  }}
                  onClick={() => setIsTotalVisible(!IsTotalVisible)}
                >
                  Recargar
                </div>
              </>
            ) : (
              <>
                {image !== "" ? (
                  <Row>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={21} sm={21} md={21} lg={21} xl={21}>
                      <Card>
                        <Spin tip="Cargando imagen..." spinning={loading}>
                          <img src={image} style={{ width: "100%" }} />
                        </Spin>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <span className="sub-title-white">RECARGA</span>
                    <br />
                    <span className="bold-title-white">DE SALDO</span>
                  </>
                )}
              </>
            )}
          </Col>
          <Col
            span={10}
            className="inputs-container background-blanco"
            style={{ textAlign: "center" }}
          >
            {IsTotalVisible ? (
              <>
                <div className="logo-container">
                  <img src={store?.logo} className="logo" />
                </div>
                <div className="line-h-20">
                  <span style={{ fontSize: 20, color: "gray" }}>¡Hola</span>
                  &nbsp;
                  <span
                    style={{ fontSize: 20, color: "gray", fontWeight: "bold" }}
                  >
                    {userData?.userName}!
                  </span>
                </div>
                <div className="line-h-20">
                  <span style={{ fontSize: 25, color: "#0a1a55" }}>SALDO</span>
                  &nbsp;
                  <span
                    style={{
                      fontSize: 25,
                      color: "#0a1a55",
                      fontWeight: "bold",
                    }}
                  >
                    ACTUAL
                  </span>
                </div>
                <div className="line-h-40">
                  <span
                    style={{
                      fontSize: 40,
                      color: "#0a1a55",
                      fontWeight: "bold",
                    }}
                  >
                    {`$ ${totalWallet}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <Row>
                  <Col span={7} className="contenedorSaldo">
                    <div className="ValueContainer">
                      <div className="tituloValor">Saldo total</div>
                      <div className="valueStyle">
                        {`$ ${totalWallet.toFixed(2)}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </div>
                      <ModalStoreWalletDetail
                        total={totalWallet}
                        movementType={"Saldo total"}
                        selectedCurrency={currency}
                      />
                    </div>
                  </Col>
                  <Col span={7} className="contenedorSaldo">
                    <div className="ValueContainer">
                      <div className="tituloValor">Para adelantar</div>
                      <div className="valueStyle">
                        {`$ ${Adelanto.toFixed(2)}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </div>
                      <ModalStoreWalletDetail
                        total={Adelanto}
                        movementType={"Disponible Adelanto"}
                        selectedCurrency={currency}
                      />
                    </div>
                  </Col>
                  <Col span={7} className="contenedorSaldo">
                    <div className="ValueContainer">
                      <div className="tituloValor">Devoluciones</div>
                      <div className="valueStyle">
                        {`$ ${SaldoDevoluciones.toFixed(2)}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </div>
                      <ModalStoreWalletDetail
                        total={SaldoDevoluciones}
                        movementType={"Devolución"}
                        selectedCurrency={currency}
                      />
                    </div>
                  </Col>
                </Row>

                <div
                  className="generic-btn"
                  onClick={() => setModalVisible(true)}
                >
                  <DollarOutlined /> Solicitar retiro de dinero
                </div>
              </>
            ) : (
              <div style={{ textAlign: "left" }}>
                <br />
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div className="logo-container-sales">
                      <img src={store?.logo} className="logo" />
                    </div>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                  <Col xs={19} sm={19} md={19} lg={19} xl={19}>
                    <div className="line-h-20">
                      <span style={{ fontSize: 15, color: "gray" }}>¡Hola</span>
                      &nbsp;
                      <span
                        style={{
                          fontSize: 15,
                          color: "gray",
                          fontWeight: "bold",
                        }}
                      >
                        {userData?.userName}!
                      </span>
                    </div>
                    <div className="line-h-20">
                      <span style={{ fontSize: 20, color: "#0a1a55" }}>
                        SALDO
                      </span>
                      &nbsp;
                      <span
                        style={{
                          fontSize: 20,
                          color: "#0a1a55",
                          fontWeight: "bold",
                        }}
                      >
                        ACTUAL
                      </span>
                    </div>
                    <div className="line-h-40">
                      <span
                        style={{
                          fontSize: 30,
                          color: "#0a1a55",
                          fontWeight: "bold",
                        }}
                      >
                        {`$ ${totalWallet}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div style={{ color: "gray", fontSize: 16 }}>
                  Para recargar el monedero debes transferir directamente a la
                  cuenta de <b>CRECEL SAS</b>
                </div>
                <br />
                <div className="line-h-20">
                  <span style={{ fontSize: 30, color: "#0a1a55" }}>
                    {localStorage.currency == "COP"
                      ? "CUENTA DE AHORROS"
                      : "CUENTA CORRIENTE"}
                  </span>
                </div>
                <div className="line-h-40">
                  <span
                    style={{
                      fontSize: 50,
                      color: "#0a1a55",
                      fontWeight: "bold",
                    }}
                  >
                    {localStorage.currency == "COP"
                      ? "BANCOLOMBIA"
                      : "BANCO PICHINCHA"}
                  </span>
                </div>
                <span
                  style={{
                    backgroundColor: "#021435",
                    color: "white",
                    textAlign: "center",
                    width: 200,
                    fontSize: 14,
                    marginRight: 20,
                    padding: 5,
                  }}
                >
                  {localStorage.currency == "COP"
                    ? "61400036577"
                    : "2100281007"}
                </span>
                <span
                  style={{
                    backgroundColor: "#021435",
                    color: "white",
                    textAlign: "center",
                    width: 200,
                    fontSize: 14,
                    padding: 5,
                  }}
                >
                  {localStorage.currency == "COP"
                    ? "NIT 901274994-1"
                    : "RUC 1793199580001"}
                </span>
                <br />
                <br />
                {localStorage.currency == "USD" && (
                  <div>
                    <img
                      src="https://drophacking.blob.core.windows.net/images/Monedero/Global66.jpeg"
                      width={"50%"}
                    />
                    <span
                      style={{
                        backgroundColor: "#021435",
                        color: "white",
                        textAlign: "center",
                        width: 200,
                        fontSize: 14,
                        padding: 5,
                      }}
                    >
                      @CARAGU671
                    </span>
                    <br />
                    <br />
                  </div>
                )}

                <div>
                  <img
                    src="https://drophacking.blob.core.windows.net/images/Monedero/cuenta-paypal.jpg"
                    width={"100%"}
                  />
                </div>
                <Row style={{ marginBottom: 15, marginTop: 30 }}>
                  <Col span={12}>
                    <div style={{ color: "gray", fontSize: 16 }}>
                      Valor a recargar:
                    </div>
                    <InputNumber
                      style={{ width: "100%" }}
                      defaultValue={0}
                      min={0}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={(e: any) => setValue(e)}
                      value={value}
                    />
                  </Col>
                  <Col span={12}>
                    <div style={{ color: "gray", fontSize: 16 }}>
                      Cargar comprobante:
                    </div>

                    <Upload {...propsUploadProductImage}>
                      <Button icon={<UploadOutlined />}>Cargar</Button>
                    </Upload>
                    {image !== "" && (
                      <Button
                        disabled={sendedData}
                        type={"primary"}
                        style={{ float: "right", backgroundColor: "#021435" }}
                        loading={loading}
                        onClick={bankData?.bankId ? saveData : showConfirm}
                      >
                        Enviar
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className={`storeWallet`}>
        <Modal
          title={`Solicitar Retiro de dinero`}
          open={modalVisible}
          onOk={solicitarDinero}
          onCancel={() => setModalVisible(false)}
        >
          <div style={{ padding: 10 }}>
            <Row gutter={[16, 16]}>
             
              <>{console.log(bankData)}</>
              {(listBanks.length > 0 && bankData) ? (
                <>
                 <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                 <b>Valor a retirar:</b>
                 <InputNumber
                   value={valorRecarga}
                   style={{ width: 150 }}
                   defaultValue={0}
                   formatter={(value: any) =>
                     `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                   }
                   parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                   onChange={(e: any) => setvalorRecarga(e)}
                 />
               </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <b>Banco:</b>
                
               
                  <Select style={{ width: "100%" }} onChange={(e)=>setBancoRetiro(e)}>
                    <Option
                      value={
                        listBanks.filter((x) => x.id == bankData?.bankId)[0]
                          .name
                      }
                      key={
                        listBanks.filter((x) => x.id == bankData?.bankId)[0]
                          .name
                      }
                    >
                      {
                        listBanks.filter((x) => x.id == bankData?.bankId)[0]
                          .name
                      }
                    </Option>
                    {(listBanks.filter((x) => x.id == bankData?.bankId)[0].name != "Global66" && bankData?.global66) &&
                    <Option value="Global66" key="Global66">
                      Global66
                    </Option>
                    }
                     {(listBanks.filter((x) => x.id == bankData?.bankId)[0].name != "PayPal" && bankData?.payPal) &&
                    <Option value="PayPal" key="PayPal">
                      PayPal
                    </Option>
                    }
                  </Select>
                  <br/>
                <Button onClick={solicitarDinero} type="primary">
                  Enviar
                </Button>
              </Col>
              </>

               )
               :
               <Result
                status="warning"
                title="Para solicitar retiro de dinero, debes registrar tus datos bancarios"
              />
              }
            </Row>
           
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default StoreWallet;
