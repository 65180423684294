import React, { useEffect, useState } from 'react'

// Ant 
import { Button, Col, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Tabs, Tooltip, Upload, Form, Checkbox, Switch, Card, Spin } from 'antd';

// Apis


import General from './Forms/General';
import Downloads from './Forms/Downloads';
import { axiosInstance } from '../../commons/services/axiosConfig';
import { ProductPermissions } from '../DroppHooks/ProductoPermissions';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

type ProductModalLibraryProps = {
    type: string,
    productId: number,
    modalVisible: boolean,
    setModalVisible: Function,
    getCopyProducts: Function
}

const ProductModalLibrary = ({ modalVisible, setModalVisible, productId, type, getCopyProducts }: ProductModalLibraryProps) => {
    const [product, setProduct] = useState<Product>({
        testimonials: "",
        promotional_Images: "",
        image: ""
    });
    const [loading, setLoading] = useState(false);
    const onCancel = () => setModalVisible(false);

    const getProductById = async () => {
        setLoading(true);
        try {
            const product = await axiosInstance.get(`/Products/${productId}`);
            setProduct(product.data);
        } catch (error) {
            message.error(`No se pudo cargar el producto. ${error}`)
        }
        setLoading(false);
    }

    const saveData = async () => {
        setLoading(true);
        let el: any = product;
        el.url_Post = "";
        el.url_Folder_Images = "";
        el.url_Folder_Videos = "";
        el.url_Folder_Segmentos = "";
        el.url_Landing = "";
        el.url_Questions = "";
        el.text_Copy = "";
        el.total_Likes = 0;
        if (type === 'Creating') {
            try {
                await axiosInstance.post(`/Products`, el);
                message.success("El producto se ha guardado correctamente");
            } catch (error) {
                message.error(`Error al guardar el producto. ${error}`);
            }
        } else {
            try {
                await axiosInstance.put(`/Products/${el.id}`, el);
                message.success('El producto se ha editado correctamente');
            } catch (error) {
                message.error(`No se ha podido editar el producto. ${error}`);
            }
        }
        setLoading(false);
        onCancel();
        getCopyProducts();
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (type === 'Editing') {
                getProductById();
            }
        }
        return () => {
            mounted = false
        }
    }, []);

    return (
        <div>
            <Modal
                open={modalVisible}
                title={`${product.id}  -  ${product.name}`}
                onCancel={onCancel}
                width={1300}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Cancelar
                    </Button>,
                    <Button type="primary" loading={loading} onClick={saveData}>
                        Guardar
                    </Button>]}
            >
                <div style={{ padding: 10 }}>
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="GENERAL" key="1">
                            <General
                                product={product}
                                setProduct={setProduct}
                            />
                        </TabPane>
                        <TabPane tab="DESCARGAS" key="4">
                            <Downloads
                                product={product}
                                setProduct={setProduct}
                            />
                        </TabPane>
                    </Tabs>
                    <br />
                    <br />
                    <Row>
                        <Col span={16}></Col>
                        <Col span={2}>
                            <Button key="back" onClick={onCancel}>
                                Cerrar
                            </Button>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={2}>
                            <Button
                                type="primary"
                                key="back"
                                loading={loading}
                                onClick={saveData}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ProductModalLibrary

type Product = {
    id?: number,
    name?: string,
    image?: string,
    description?: string,
    cost?: number,
    selling_price?: number,
    suggested_selling_price?: number,
    provider?: string,
    stock?: number,
    warranty_days?: number,
    youtube?: string,
    commission_value1?: number,
    commission_value2?: number,
    category_id?: number,
    max_Selling_price?: number,
    promotional_Images?: string,
    url_Post?: string,
    url_Folder_Images?: string,
    url_Folder_Videos?: string,
    url_Folder_Segmentos?: string,
    url_Landing?: string,
    url_Questions?: string,
    text_Copy?: string,
    testimonials?: string,
    total_Likes?: number,
    weight?: number,
    madeInOwnLab?: boolean,
    isFree?: boolean,
    isActive?: boolean,
    usD_Selling_price?: number,
    usD_Suggested_selling_price?: number,
    usD_Commission_value1?: number,
    usD_Commission_value2?: number,
    usD_Max_Selling_price?: number,
    isActiveEcuador?: boolean
}

