import React, { useContext, useEffect, useState } from 'react';
import { IHeaderProps } from './IHeaderProps';
import './Header.css';
import { Avatar, Col, Input, Popover, Row } from 'antd';
import { UserOutlined, MobileOutlined } from '@ant-design/icons';
import { PageContext } from '../../Hooks/ContextProvider';
import { useHeader } from '../../Hooks/useHeader';
import ModalDetalleGuia from '../ModalDetalleGuia/ModalDetalleGuia';
import SearchByPhone from '../SearchByPhone/SearchByPhone';
import { storeModel } from '../../Models/store';
import { axiosInstance } from '../../commons/services/axiosConfig';
import UpdateStoreData from '../UpdateStoreData/UpdateStoreData';
import UpdateBankStoreData from '../UpdateBankStoreData/UpdateBankStoreData';
import { store_bank } from '../../Models/bank.model';

const { Search } = Input;

const Header = (props: IHeaderProps) => {

  const [visible, setVisible] = useState<boolean>(false);
  const [guia, setGuia] = useState<string>("");
  const [updateStoreDataVisible, setUpdateStoreDataVisible] = useState(false);
  const [updateBankStoreDataVisible, setUpdateBankStoreDataVisible] = useState(false);
  const [store, setStore]: any = useState<storeModel>();
  const [store_bank, setStore_bank]: any = useState<store_bank>();

  const {
    userData,
  } = useContext(PageContext);

  const {
    LogOut
  } = useHeader();

  useEffect(() => {
    if (window.location.pathname !== '/PendingApprove' &&
      window.location.pathname !== '/updateProfileStore' && window.location.pathname !== '/contracts') {
      getStoreData();
      getBankStoreData();
    }
  }, []);


  const getStoreData = () => {
    if (userData) {
      axiosInstance.get<storeModel>(`/Stores/${userData.storeID}`).then(res => {
        if (res.data.stateId == 1 ||res.data.stateId == 4) {
          window.location.href = "/PendingApprove";
        }
        if (res.data.stateId == 3) {
          window.location.href = "/updateProfileStore";
        }
        else {
          if (res.data.contractStores?.length > 0 && res.data.contractStores?.find(x => x.status == 0)) {
            window.location.href = "/contracts";

          } else {
            setStore(res.data);
          }
        }
      })
    }
  }

  const getStoreDataUpdate = () => {
    if (userData) {
      axiosInstance.get<storeModel>(`/Stores/${userData.storeID}`).then(res => {
        setStore(res.data);
      })
    }
  }

  const getBankStoreData = () => {
    if (userData) {
      axiosInstance.get<store_bank>(`/Store_Banks/${userData.storeID}`).then(res => {
        setStore_bank(res.data);
      });
    }
  }



  const content = (
    <div className='text-center'>
      <Avatar size={64} icon={<UserOutlined />} />
      <div className='user-blue'>{userData?.userName}</div>
      <div className='user-email-text-popover'>{userData?.email}</div>
      <div className='text-popover' onClick={() => {
        getStoreDataUpdate()
        setUpdateStoreDataVisible(true);
      }}>Actualizar datos de mi tienda</div>
      <div className='text-popover' onClick={() => setUpdateBankStoreDataVisible(true)}>Actualizar datos del banco</div>
      <br />
      <img src="https://drophacking.blob.core.windows.net/images/Menu/BOTON-DE-CERRAR-SESIÓN.png" className='btn-cerrar-sesion' onClick={LogOut} />
    </div>
  );

  const searchGuia = () => {
    if (guia != "") {
      setVisible(true);
    }
  }

  return (
    <div className="header-container">
      <Row>
        <Col xs={10} sm={10} md={10} lg={4} xl={4}>
          <img src='https://drophacking.blob.core.windows.net/images/CortinaTiendas/LOGO-CRECEL-PARA-MENÚ-DE-TIENDAS.png' className='logo' />
        </Col>
        <Col xs={4} sm={4} md={4} lg={9} xl={9}></Col>
        <Col xs={10} sm={10} md={10} lg={3} xl={3} className="pt-30">
          <Search placeholder="Buscar guia" onChange={(e) => setGuia(e.target.value)} onSearch={searchGuia} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={1} xl={1}>
          <SearchByPhone />
        </Col>
        <Col xs={0} sm={0} md={0} lg={1} xl={1}></Col>
        <Col xs={0} sm={0} md={0} lg={2} xl={2} className="pt-30">
          <span className='user-name-text'>
            <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-MONEDA.png" className='user-icon' />
            &nbsp; {localStorage.currency}
          </span>

        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4} className="user-data-container">
          <Popover placement="bottomRight" content={content} trigger="click">
            <span className='user-name-text'> {userData?.userName} &nbsp;
              <img src="https://drophacking.blob.core.windows.net/images/Menu/ICONO-DE-PERFIL.png" className='user-icon' />
            </span>
            <br />
            <span className='user-email-text'>{userData?.email}</span>        </Popover>
        </Col>
        {visible &&
          <ModalDetalleGuia visible={visible} setVisible={setVisible} guia={guia} />
        }
      </Row>
      <UpdateStoreData
        updateStoreDataVisible={updateStoreDataVisible}
        setUpdateStoreDataVisible={setUpdateStoreDataVisible}
        store={store}
        setStore={setStore}
      />
      <UpdateBankStoreData
        updateBankStoreDataVisible={updateBankStoreDataVisible}
        setUpdateBankStoreDataVisible={setUpdateBankStoreDataVisible}
        setStore_bank={setStore_bank}
        store_bank={store_bank}
      />
    </div>
  );
};

export default Header;
