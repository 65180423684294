import React, { useState, useEffect, useContext } from 'react';
import { IUpdateStoreDataProps } from './IUpdateStoreDataProps';
import './UpdateStoreData.css';
import { Modal, Button, Row, Input, Col, Select, message, Upload } from 'antd';
import utils from '../../utils';
import { UploadOutlined } from '@ant-design/icons';
import ModalUpdateStoreDocuments from '../ModalUpdateStoreDocuments/ModalUpdateStoreDocuments';
import { city } from '../../Models/city.model';
import { axiosInstance } from '../../commons/services/axiosConfig';
import { PageContext } from '../../Hooks/ContextProvider';
import { Status } from '../ApproveStore/Status';
const { Option } = Select;

const UpdateStoreData = (props?: IUpdateStoreDataProps) => {
  const [listCities, setListCities]: any = useState<Array<city>>([]);
  const [listCitiesFiltered, setlistCitiesFiltered]: any = useState<Array<city>>([]);
  const [loading, setLoading] = useState(false);
  const {
    userData,
  } = useContext(PageContext);

  useEffect(() => {
    getCities();
  }, []);

  const handleOk = () => {
    setLoading(true);
    if (localStorage.storeID !== "") {
      let store = props?.store
      if (store) store.stateId = Status['En Actualización'];
      axiosInstance.put(`/Stores/${props?.store.id}`, store).then(res => {
        message.success("La tienda fue actualizada existosamente");
        props?.setUpdateStoreDataVisible(false);
        setLoading(false);
      })
        .catch(err => { message.error('Se presentó un error al actualizar la tienda, por favor intentalo nuevamente', 5); setLoading(false) })
    }
  }

  const getCities = () => {
    axiosInstance.get(`/Cities`).then(res => {
      setListCities(utils.sortArray(res.data, 'name'));
      setlistCitiesFiltered(utils.sortArray(res.data, 'name'));
    })
  }

  const handleCancel = () => {
    props?.setUpdateStoreDataVisible(false);
  }

  const updateState = (value: any, prop: string) => {
    let arrayAux = { ...props?.store as any }
    arrayAux[prop] = value;
    props?.setStore(arrayAux);
  }

  const handleSearchCiudad = (value: string) => {
    if (value) {
      setlistCitiesFiltered(listCities.filter((x: city) => x.name.toLowerCase().includes(value)));
    } else {
      setlistCitiesFiltered([])
    }
  }

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function onchangeUploadImage(event: any) {
    const file = event.target.files[0];
    let arrayAux = { ...props?.store as any }
    arrayAux["logo"] = await toBase64(file);
    props?.setStore(arrayAux);
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const propsUploadLogo: any = {
    beforeUpload(file: any) {
      return new Promise(resolve => {
        const reader = new FileReader();
        uploadLogo(file);
        reader.readAsDataURL(file);
        reader.onload = () => {
        };
      });
    },
  };

  const uploadLogo = (image: any) => {
    if (userData) {
      let formData = new FormData();
      var extension = image.type
      const myNewFile = new File([image], `${localStorage.storeID}-${uuidv4()}.${extension.split('/')[1]}`, { type: image.type });
      formData.append('Attachments', myNewFile);
      formData.append('folderName', `${localStorage.storeID}-LogoTienda`);
      formData.append('strContainerName', '9999');
      axiosInstance.post(`/BlobStorage/UploadImageGallery`, formData).then((res: any) => {
        let arrayAux = { ...props?.store as any }
        arrayAux["logo"] = res.data;
        props?.setStore(arrayAux);
      })
        .catch(erro => {
          setLoading(false)
          message.error('Se presentó un error al cargar la imagen, intentalo nuevamente')
          console.error(erro)
        })
    }
  }

  return (
    <div className={`updateStoreData`}>
      {props?.store !== undefined &&
        <Modal
          title="Actualizar datos de la tienda"
          open={props?.updateStoreDataVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={loading}
        >
          <div style={{ padding: 10 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Nombre de la tienda:</b>
                <Input value={props?.store.name} onChange={(e) => updateState(e.target.value, 'name')} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Dirección:</b>
                <Input value={props?.store.adress} onChange={(e) => updateState(e.target.value, 'adress')} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Ciudad:</b>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder="Ciudad"
                  defaultValue={props?.store.city}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchCiudad}
                  onChange={(e) => updateState(e, 'city')}
                  notFoundContent={null}
                >
                  {listCitiesFiltered.map((x: city) => <Option key={x.id} value={x.name}>{x.name}</Option>)}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Expedición cédula:</b>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder="Ciudad"
                  defaultValue={props?.store.identification_expedition}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchCiudad}
                  onChange={(e) => updateState(e, 'identification_expedition')}
                  notFoundContent={null}
                >
                  {listCitiesFiltered.map((x: city) => <Option key={x.id} value={x.name}>{x.name}</Option>)}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Teléfono:</b>
                <Input value={props?.store.phone} onChange={(e) => updateState(e.target.value, 'phone')} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Celular:</b>
                <Input value={props?.store.cel} onChange={(e) => updateState(e.target.value, 'cel')} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <b>Actualizar documentos:</b>
                <ModalUpdateStoreDocuments storeId={localStorage.storeID} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <b>Logo:</b><br />
                <Upload {...propsUploadLogo}>
                  <Button icon={<UploadOutlined />}>Cargar</Button>
                </Upload>
                {/* <input type="file" name="logo" style={{ width: '100%' }} onChange={(e) => onchangeUploadImage(e)} /> */}
              </Col>
            </Row>
            <Row>
              <Col span={14}></Col>
              <Col span={4}>
                <div className={'contenedorLogo'}>
                  <img alt="icono" src={props?.store.logo} style={{ width: '100%', borderRadius: 10 }} />
                </div>
              </Col>
              <Col span={2}></Col>
              <Col span={4}>
                <Button onClick={handleOk} className='generic-btn'>Guardar</Button>
              </Col>
            </Row>
          </div>
        </Modal>
      }
    </div>
  );
};

export default UpdateStoreData;
