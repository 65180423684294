import { message } from "antd";
import { useState } from "react";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { Inventory, InventoryDetail, Transfer } from "../../Models/Products";

export const useWareHouseInventoryAdmin = () => {
  const [dispatchWarehouseSelected, setDispatchWarehouseSelected] = useState<number>();
  const [dispatchInventoryList, setDispatchInventoryList] = useState<InventoryDetail[]>([]);
  const [devolutionWarehouseSelected, setDevolutionWarehouseSelected] = useState<number>();
  const [devolutionInventoryList, setDevolutionInventoryList] = useState<InventoryDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<number>();
  const [inventoryList, setInventoryList] = useState<Inventory[]>([]);

  const insertInventoryMovement = (product: any) => {
    axiosInstance.post(`Inventory_Movement`, product
    ).then(() => {
      message.success('Movimiento de inventario agregado exitosamente');
      onChangeWarehouse(product.warehouseId)
    })
      .catch((error) => {
        message.error(`Error al agregar el movimiento de inventario Error: ${error.message}`);
      });
  }

  const insertTransferMovement = (transfer: Transfer) => {
    const dispatch = { ...transfer, warehouseId: transfer.initialWarehouseId, movement_Type: 'Salida Despachada' }
    const entry = { ...transfer, warehouseId: transfer.finalWarehouseId, movement_Type: 'Entrada' }

    axiosInstance.post(`Inventory_Movement`, dispatch
    ).then(() => {
      axiosInstance.post(`Inventory_Movement`, entry).then(() => {
        onChangeWarehouse(transfer.initialWarehouseId)
      });
    })
      .then(() => {
        message.success('Traspaso exitoso');
      })
      .catch((error) => {
        message.error(`Error al realizar el traspaso Error: ${error.message}`);
      });
  }

  const onChangeDispatchWarehouse = (item: number) => {
    setDispatchWarehouseSelected(item);
    getWarehouseDispatchInventory(item);
  };

  const onChangeDevolutionWarehouse = (item: number) => {
    setDevolutionWarehouseSelected(item);
    getWarehouseDevolutionInventory(item);
  };

  function getWarehouseDispatchInventory(warehouseId: number) {
    setLoading(true);
    if (warehouseId === 0) {
      axiosInstance.get(
        `Inventory_Movement/GetInventoryDispatched`
      ).then((res) => {
        setLoading(false);
        setDispatchInventoryList(res.data);
      });
    } else {
      axiosInstance.get(
        `Inventory_Movement/InventoryDispatchedById/${warehouseId}`
      ).then((res) => {
        setLoading(false);
        setDispatchInventoryList(res.data);
      });
    }
  }

  function getWarehouseDevolutionInventory(warehouseId: number) {
    setLoading(true);
    if (warehouseId === 0) {
      axiosInstance.get(
        `Inventory_Movement/GetInventoryReturned`
      ).then((res) => {
        setLoading(false);
        setDevolutionInventoryList(res.data);
      });
    } else {
      axiosInstance.get(
        `Inventory_Movement/InventoryReturnedById/${warehouseId}`
      ).then((res) => {
        setLoading(false);
        setDevolutionInventoryList(res.data);
      });
    }
  }

  function getProducts() {
    axiosInstance.get(`Products`
    ).then((res) => {
      setProductList(res.data);
    })
  }

  const getWarehouseInventory = (warehouseId: number) => {
    setLoading(true);

    if (warehouseId === 0) {
      axiosInstance.get(`Inventory_Movement/GetInventory`)
        .then((res) => {
          setLoading(false);
          setInventoryList(res.data);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    } else {
      axiosInstance.get(
        `Inventory_Movement/GetInventoryByWarehouseId?warehouseId=${warehouseId}`
      )
        .then((res) => {
          setLoading(false);
          setInventoryList(res.data);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  const onChangeWarehouse = (item: number) => {
    setSelectedWarehouse(item);
    getWarehouseInventory(item);
  }

  return {
    dispatchWarehouseSelected,
    dispatchInventoryList,
    onChangeDispatchWarehouse,
    devolutionWarehouseSelected,
    devolutionInventoryList,
    onChangeDevolutionWarehouse,
    loading,
    productList,
    insertInventoryMovement,
    insertTransferMovement,
    getProducts,
    onChangeWarehouse,
    selectedWarehouse,
    inventoryList
  };
};