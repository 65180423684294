import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { storeModel } from "../../Models/store";
import { StorePanelResume, StoreResume } from "../../Models/storeResume";
import { PageContext } from "../ContextProvider";

export const usePanel = () => {
  const [storeResume, setStoreResume] = useState<StoreResume[]>([]);
  const [storePanelResume, setstorePanelResume] = useState<StorePanelResume>();
  const [TotalBills, setTotalBills] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalWallet, setTotalWallet] = useState(0);
  const [store, setStore] = useState<storeModel>();
  const [ChartDataSource, setChartDataSource] = useState([]);
  const [dayQuantity, setDayQuantity] = useState<number>(-7);

  const { userData } = useContext(PageContext);

  useEffect(() => {
    GetStoreResume();
    getTotalBills();
    getHistoryMovement();
    getStoreData();
    GetStorePanelResume();
  }, [dayQuantity]);

  const GetStoreResume = async () => {
    setLoading(true);
    let resp = await axiosInstance.get(
      `/Stores/${localStorage.currency == 'COP' ? 'GetStoreResume': 'GetUSDStoreResume' }?storeId=${userData?.storeID}`
    );
    if (resp.status === 200) {
        let chartData = resp.data.slice(dayQuantity).map((x:StoreResume)=>{
            return {day: moment(x.fecha).format("YYYY-MM-DD"),total:x.ingresos}; 
          })
      setChartDataSource(chartData);
      setStoreResume(resp.data.slice(dayQuantity));
      setLoading(false);
    }
  };

  const GetStorePanelResume = async () => {
    setLoading(true);
    let resp = await axiosInstance.get(`/Stores/GetStorePanelResume?storeId=${userData?.storeID}&CountryId=${localStorage.currency == 'COP' ? 1 : 3 }`
    );
    if (resp.status === 200) {
      setstorePanelResume(resp.data);
      setLoading(false); 
    }
  }

  const getTotalBills = async () => {
    setLoading(true);
    if (userData?.storeID !== undefined) {
      let resp = await axiosInstance.get(
        `/Bills/GetTotalBillsByStore?storeId=${userData?.storeID}&currency=${localStorage.currency}`
      );
      if (resp.status === 200) {
        setTotalBills(resp.data);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const getHistoryMovement = async () => {
    setLoading(true);
    let resp = await axiosInstance.get(
      `/StoreWallet/TotalPurse?storeId=${userData?.storeID}&currency=${localStorage.currency}`
    );
    if (resp.status === 200) {
      setTotalWallet(resp.data);
      setLoading(false);
    }
  };

  const getStoreData = async () => {
    if (userData?.storeID !== undefined) {
        let resp = await axiosInstance.get(
            `/Stores/${userData?.storeID}`
          );
          if (resp.status === 200) {
            setStore(resp.data);
            setLoading(false);
          }
    }
  }

  return {
    storeResume,
    TotalBills,
    loading,
    totalWallet,
    store,
    ChartDataSource,
    setDayQuantity,
    dayQuantity,
    storePanelResume
  };
};
