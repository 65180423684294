import {notification} from "antd";

export function handleGlobally(error: any) {
    console.error('Global Error Handler:', error);

    if (error.code === 'ERR_NETWORK') {
        notification.error({message: 'Error de red. Verifique su conexión a internet o intente más tarde.'});
    } else if (error.code === 'ERR_BAD_REQUEST') {
        switch (error.response.status) {
            case 401:
                // Unauthorized access, you might want to redirect to login
                localStorage.removeItem('auth_token');
                window.location.href = `/Login`;
                break;
            case 403:
                // Forbidden access
                notification.error({message: 'Usuario no autorizado.'})
                break;
            case 404:
                // Resource not found
                notification.error({message: 'Recurso solicitado no encontrado.'})
                break;
            case 500:
                notification.error({message: 'Error interno del servidor. Intente más tarde.'});
                break;
            case 502:
                notification.error({message: 'Error de puerta de enlace (Bad Gateway).'});
                break;
            case 503:
                notification.error({message: 'Servicio no disponible. Intente más tarde.'});
                break;
            default:
                // General error handling
                notification.error({message: 'Un error ocurrió. Vuelva a intentar'})
                break;
        }
    } else if (error.request) {
        notification.error({message: 'No se recibió respuesta. Por favor, verifique su conexión.'});
    } else {
        notification.error({message: `Error inesperado: ${error.message}`});
    }
}
