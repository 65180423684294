import React, { useContext, useEffect, useState } from "react";
import { IPendingRechargeProps } from "./IPendingRechargeProps";
import "./PendingRecharge.css";
import {
  Table,
  Button,
  Modal,
  Input,
  InputNumber,
  message,
  Tabs,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { EyeOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  store_wallet,
  store_wallet_movement,
} from "../../Models/store_wallet.model";
import { storeModel } from "../../Models/store";
import { axiosInstance } from "../../commons/services/axiosConfig";
import ModalStoreBankData from "../ModalStoreBankData/ModalStoreBankData";
import ModalSendEmail from "../ModalSendEmail/ModalSendEmail";
import ModalStoresBank from "../ModalStoresBank/ModalStoresBank";
import ModalSaldoTienda from "../ModalSaldoTienda/ModalSaldoTienda";
import { PageContext } from "../../Hooks/ContextProvider";
import StoreWalletManagement from "../StoreWalletManagement/StoreWalletManagement";

const { confirm } = Modal;
const { TabPane } = Tabs;

const PendingRecharge = (props: IPendingRechargeProps) => {
  const [store_wallet_movements, setstore_wallet_movements] = useState<
    Array<store_wallet_movement>
  >([]);
  const [store_wallet_movementsAprobados, setstore_wallet_movementsAprobados] =
    useState<Array<store_wallet_movement>>([]);
  const [
    store_wallet_movementsRechazados,
    setstore_wallet_movementsRechazados,
  ] = useState<Array<store_wallet_movement>>([]);
  const [store_wallet_movementsRetiro, SetStore_wallet_movementsRetiro] =
    useState<Array<store_wallet_movement>>([]);
  const [storesList, setStoresList]: any = useState<storeModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [valorRecarga, setvalorRecarga]: any = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [store_wallet_movementSelected, setStore_wallet_movementSelected]: any =
    useState<store_wallet_movement>();
  const [disabled, setdisabled] = useState<boolean>(false);
  const [modalDetalleRecarga, setModalDetalleRecarga] =
    useState<boolean>(false);
  const [modalSaldotiendaVisible, setmodalSaldotiendaVisible] =
    useState<boolean>(false);
  const [modalStoreBank, setModalStoreBank] = useState<boolean>(false);
  const [selectedStoreId, setSelectedStoreId] = useState<number>(0);
  const [dataSelected, setDataSelected] = useState();
  const [store, setStore] = useState<storeModel>();
  const [producers, setProducers] = useState<any>([]);

  const { userData } = useContext(PageContext);

  useEffect(() => {
    setLoadingData(true);
    getStoreData();
  }, [disabled, modalDetalleRecarga]);

  useEffect(() => {
    getStoreList();
  }, [producers]);

  const getStoreData = () => {
    if (userData?.storeID) {
      axiosInstance.get(`/Stores/${userData.storeID}`).then((res) => {
        if (userData?.roles.includes("SuperAdmin")) {
          if (document.location.pathname !== "/storeHome") {
            window.location.href = "/storeHome";
          }
        }
        setStore(res.data);
      });
    }
  };

  const getStoreList = async () => {
    setStoresList([]);
    let res = await axiosInstance.get<storeModel[]>(`/Stores/GetStoreList`); //storesAPI.getStoreList();
    setStoresList(res.data);
    getPendingRecharge(res.data, "Pendiente");
    getPendingRecharge(res.data, "SRetiro");
    getPendingRecharge(res.data, "Aprobado");
    getPendingRecharge(res.data, "Rechazado");
  };

  const getPendingRecharge = async (stores: any, state: string) => {
    setLoadingData(true);
    try {
      let json = await axiosInstance.get(`/StoreWalletMovement/GetByState/${state}`);
      if (json.data.length === 0 && state === "SRetiro") {
        SetStore_wallet_movementsRetiro([]);
      }
      if (json.data.length === 0 && state === "Pendiente") {
        setstore_wallet_movements([]);
      }
      if (json.data.length === 0 && state === "Aprobado") {
        setstore_wallet_movementsAprobados([]);
      }
      if (json.data.length === 0 && state === "Rechazado") {
        setstore_wallet_movementsRechazados([]);
      }

      json.data.map((x: any, i: number) => {
        if (x.storeId !== null) {
          json.data[i].name = stores.filter(
            (s: storeModel) => s.id === x.storeId
          )[0].name;
        }
        json.data[i].created_at = json.data[i].created_at.split("T")[0];
        if (i + 1 === json.data.length) {
          if (state === "Pendiente") {
            setstore_wallet_movements(json.data);
          }
          if (state === "Aprobado") {
            setstore_wallet_movementsAprobados(json.data);
          }
          if (state === "Rechazado") {
            setstore_wallet_movementsRechazados(json.data);
          }
          if (state === "SRetiro") {
            var data = json.data.map((r: store_wallet_movement) => {
              r.state = "Solicitud Retiro";
              return r;
            });
            SetStore_wallet_movementsRetiro(data);
          }
        }
      });
      setLoadingData(false);
    } catch (error) {}
  };

  function verComprobante(id: number) {
    let store_wallet = store_wallet_movements.filter((x) => x.id === id);
    if (store_wallet.length > 0) {
      setStore_wallet_movementSelected(store_wallet[0]);
      setModalDetalleRecarga(true);
    } else {
      let store_wallet = store_wallet_movementsAprobados.filter(
        (x) => x.id === id
      );
      if (store_wallet.length > 0) {
        setStore_wallet_movementSelected(store_wallet[0]);
        setModalDetalleRecarga(true);
      } else {
        let store_wallet = store_wallet_movementsRechazados.filter(
          (x) => x.id === id
        );
        if (store_wallet.length > 0) {
          setStore_wallet_movementSelected(store_wallet[0]);
          setModalDetalleRecarga(true);
        }
      }
    }
  }

  const guardarRecarga = () => {
    setLoading(true);
    store_wallet_movementSelected.state = "Aprobado";
    axiosInstance.put(
      `/StoreWalletMovement/${store_wallet_movementSelected.id}`,
      store_wallet_movementSelected
    ).then((response) => {
      setdisabled(true);
      if (store_wallet_movementSelected.producerId > 0) {
        let data: store_wallet = {
          producerId: store_wallet_movementSelected.producerId,
          movement_type: "Recarga",
          value: valorRecarga,
          created_by: parseInt(localStorage.userID),
          currency: store_wallet_movementSelected.currency,
        };
        axiosInstance.post(`/StoreWallet`, data).then((res) => {
          getPendingRecharge(storesList, "Pendiente");
          setLoading(false);
          setModalVisible(false);
          message.success("El monedero de la tienda fue actualizado");
          setStore_wallet_movementSelected();
          setModalDetalleRecarga(false);
          setdisabled(false);
          setvalorRecarga(0);
        });
      } else {
        let data: store_wallet = {
          storeId: store_wallet_movementSelected.storeId,
          movement_type: "Recarga",
          value: valorRecarga,
          created_by: parseInt(localStorage.userID),
          currency: store_wallet_movementSelected.currency
        };
        axiosInstance.post(`/StoreWallet`, data).then((res) => {
          getPendingRecharge(storesList, "Pendiente");
          setLoading(false);
          setModalVisible(false);
          message.success("El monedero de la tienda fue actualizado");
          setStore_wallet_movementSelected();
          setModalDetalleRecarga(false);
          setdisabled(false);
          setvalorRecarga(0);
        });
      }
    });
  };

  const rechazarRecarga = () => {
    store_wallet_movementSelected.state = "Rechazado";
    axiosInstance.put(
      `/StoreWalletMovement/${store_wallet_movementSelected.id}`,
      store_wallet_movementSelected
    ).then((response) => {
      setdisabled(true);
      getPendingRecharge(storesList, "Pendiente");
      message.warning(
        'La recarga fue rechazada, puedes notificar a la tienda en el botón "Enviar Correo"',
        8
      );
    });
  };

  const columnsAprobadas = [
    {
      title: "Tienda",
      dataIndex: "name",
      key: "store",
    },
    {
      title: "Comprobante",
      key: "image",
      render: (text: any, record: any) => (
        <EyeOutlined onClick={() => verComprobante(record.id)} />
      ),
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
    },
  ];

  const columnsRetiro = [
    {
      title: "Tienda",
      dataIndex: "name",
      key: "store",
    },
    {
      title: "Ver Saldo Tienda",
      key: "SaldoTienda",
      render: (text: any, record: store_wallet_movement) => (
        <EyeOutlined onClick={() => verModalSaldoTienda(record)} />
      ),
    },
    {
      title: "Ver Datos Bancarios",
      key: "DatosBancarios",
      render: (text: any, record: any) => (
        <EyeOutlined onClick={() => verModalStoreBank(record.storeId)} />
      ),
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (text: any, record: store_wallet_movement) =>
        `$ ${record.value} ${record.currency ? record.currency : "COP"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Consignar en",
      dataIndex: "bankName",
      key: "bankName",
    }
  ];

  const verModalSaldoTienda = (record: any) => {
    if (localStorage.storeID !== null) {
      setLoading(true);
      setSelectedStoreId(record.storeId);
      setmodalSaldotiendaVisible(true);
      setDataSelected(record);
    }
  };

  const verModalStoreBank = (x: number) => {
    if (localStorage.storeID !== null) {
      setSelectedStoreId(x);
      setModalStoreBank(true);
    }
  };

  const columns = [
    {
      title: "Tienda",
      dataIndex: "name",
      key: "store",
    },
    {
      title: "Comprobante",
      key: "image",
      render: (text: any, record: any) => (
        <EyeOutlined onClick={() => verComprobante(record.id)} />
      ),
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (text: any, record: store_wallet_movement) =>
        `$ ${record.value} ${record.currency ? record.currency : "COP"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    }
  ];

  const closeDetalleModalRecarga = () => {
    setModalDetalleRecarga(false);
    setdisabled(false);
    setStore_wallet_movementSelected({});
    setvalorRecarga();
    getPendingRecharge(storesList, "Pendiente");
  };

  return (
    <div className={`pendingRecharge`}>
      <div className={"PageTitle"}>Recargas de tiendas</div>
      <Row>
        <Col span={2}></Col>
        <Col span={20}>
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="Pendientes por recargar" key="1">
              <Table
                dataSource={store_wallet_movements}
                columns={columns}
                loading={loadingData}
              />
            </TabPane>
            <TabPane tab="Recargados" key="2">
              <Table
                dataSource={store_wallet_movementsAprobados}
                columns={columnsAprobadas}
                loading={loadingData}
              />
            </TabPane>
            <TabPane tab="Rechazados" key="3">
              <Table
                dataSource={store_wallet_movementsRechazados}
                columns={columnsAprobadas}
                loading={loadingData}
              />
            </TabPane>
            <TabPane tab="Solicitudes de retiro" key="4">
              <Table
                dataSource={store_wallet_movementsRetiro}
                columns={columnsRetiro}
                loading={loadingData}
              />
            </TabPane>
            {userData?.roles.includes("SuperAdmin") &&
              <TabPane tab="Monedero" key="5">
                    <StoreWalletManagement/>
              </TabPane>
            }
          </Tabs>
        </Col>
      </Row>

      {store_wallet_movementSelected !== undefined && (
        <Modal
          title={`Recargar monedero: ${store_wallet_movementSelected.name}`}
          open={modalVisible}
          onOk={guardarRecarga}
          confirmLoading={loading}
          onCancel={() => {
            setvalorRecarga(0);
            setModalVisible(false);
          }}
        >
          <div style={{ padding: 20 }}>
            <b style={{ fontSize: 20 }}>Valor a recargar</b>&nbsp;&nbsp;
            <InputNumber
              value={valorRecarga}
              style={{ width: 150 }}
              defaultValue={0}
              formatter={(value: any) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(e: any) => setvalorRecarga(e)}
            />
            <br />
            <br />
            <Row>
              <Col span={15}></Col>
              <Col span={3}>
                <Button
                  key="back"
                  onClick={() => {
                    setvalorRecarga(0);
                    setModalVisible(false);
                  }}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={2}></Col>
              <Col span={3}>
                <Button type="primary" key="back" onClick={guardarRecarga}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      )}

      {store_wallet_movementSelected !== undefined && (
        <Modal
          title={`Recargar monedero: ${store_wallet_movementSelected.name}`}
          open={modalDetalleRecarga}
          width={1000}
          onOk={() => closeDetalleModalRecarga()}
          confirmLoading={loading}
          onCancel={() => closeDetalleModalRecarga()}
          footer={[
            <Button key="back" onClick={() => closeDetalleModalRecarga()}>
              Cerrar
            </Button>,
          ]}
        >
          <Row>
            <Col span={12}>
              <div>
                <img
                  src={store_wallet_movementSelected.bank_transfer_receipt}
                  alt="Comprobante"
                  style={{ width: "100%" }}
                />
              </div>
              <br />
            </Col>
            <Col span={1}></Col>
            <Col span={11}>
              {store_wallet_movementSelected.storeId > 0 ? (
                <ModalStoreBankData
                  storeId={store_wallet_movementSelected.storeId}
                  storeName={store_wallet_movementSelected.name}
                />
              ) : (
                <h3>Recarga Productor: {store_wallet_movementSelected.name}</h3>
              )}
              <br />
              {store_wallet_movementSelected.state === "Pendiente" && (
                <Row>
                  <Col span={12}>
                    <Button
                      onClick={() => {
                        rechazarRecarga();
                      }}
                      disabled={disabled}
                      style={{ marginBottom: 10 }}
                    >
                      Rechazar
                    </Button>
                    <br />
                  </Col>
                  <Col span={12}>
                    <Button
                      disabled={disabled}
                      type="primary"
                      onClick={() => {
                        setModalVisible(true);
                      }}
                    >
                      Aprobar
                    </Button>
                  </Col>
                </Row>
              )}
              {store_wallet_movementSelected.storeId > 0 && (
                <ModalSendEmail
                  StoreId={store_wallet_movementSelected.storeId}
                />
              )}
              <br />
              Valor recarga:
              <div style={{ fontSize: 30 }}>
                {`$ ${store_wallet_movementSelected.value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
                &nbsp;{store_wallet_movementSelected.currency}
              </div>
            </Col>
          </Row>
        </Modal>
      )}
      <ModalSaldoTienda
        visible={modalSaldotiendaVisible}
        dataSelected={dataSelected}
        setVisible={setmodalSaldotiendaVisible}
        storeId={selectedStoreId}
        rechargePending={getStoreList}
      />
      {modalStoreBank && (
        <ModalStoresBank
          visible={modalStoreBank}
          setVisible={setModalStoreBank}
          storeId={selectedStoreId}
        />
      )}
    </div>
  );
};

export default PendingRecharge;
