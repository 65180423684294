import { message } from 'antd';
import { useContext, useEffect, useState } from 'react'
import { axiosInstance } from '../../../commons/services/axiosConfig';
import { PageContext } from '../../../Hooks/ContextProvider';




export const useModalUpdateStoreDocuments = (storeId: number) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const [documentosCargados, setDocumentosCargados] = useState<boolean>(false);
  const [storeDocuments, setStoreDocuments] = useState<StoreDocuments>({});
  const { userData } = useContext(PageContext);

  const closeModal = () => {
    setVisible(false)
  }

  const openModal = () => {
    setVisible(true);
  }

  const getStoreDocuments = async () => {
    const res = await axiosInstance.get(`/StoreDocument/${userData?.storeID}`);
    setStoreDocuments(res.data);
    console.log("trayendo documentos");
    console.log(res.data);

  }

  const uploadProductImageRut: any = {
    beforeUpload(file: any) {
      return new Promise(() => {
        const reader = new FileReader();
        uploadDocument("RUT", file)
        reader.readAsDataURL(file);
      });
    }
  };

  const uploadProductImageCedula1: any = {
    beforeUpload(file: any) {
      return new Promise(resolve => {
        const reader = new FileReader();
        uploadDocument("CEDULA1", file)
        reader.readAsDataURL(file);
      });
    }
  };

  const uploadProductImageCedula2: any = {
    beforeUpload(file: any) {
      return new Promise(() => {
        const reader = new FileReader();
        uploadDocument("CEDULA2", file)
        reader.readAsDataURL(file);
      });
    }
  };


  const uploadDocument = async (document: string, image: any) => {
    try {
      if (image.size > 1000000) {
        message.warning('El archivo no puede superar 1MB o 1.000 KB')
      } else {
        let formData = new FormData();
        const myNewFile = new File([image], `${localStorage.storeID}-${image.name}-${document}.${image.type}`, { type: image.type });
        formData.append('Attachments', myNewFile);
        formData.append('strContainerName', `store-documents`);
        formData.append('folderName', `store-documents`);
        await axiosInstance.post(`/BlobStorage/UploadImageGallery`, formData).then((res: any) => {
          message.success(`${image.name} se ha cargado correctamente`);
          if (document === "RUT") {
            setStoreDocuments({ ...storeDocuments, rut: res.data });
          }
          if (document === "CEDULA1") {
            setStoreDocuments({ ...storeDocuments, cedula1: res.data });

          }
          if (document === "CEDULA2") {
            setStoreDocuments({ ...storeDocuments, cedula2: res.data });
          }
        })
      }
    } catch (error) {
      message.error(`Error al cargar la imagen. ${error}`)
    }
  }

  const saveUploadDocument = async () => {
    try {
      await axiosInstance.post(`/StoreDocument`, storeDocuments);
      message.success('Se han guardado los documentos correctamente');
      closeModal();
    } catch (error) {
      message.error(`Error el guardar los datos. ${error}`);
    }
  }

  useEffect(() => {
    if (!documentosCargados) {
      console.log("efecct");
      getStoreDocuments();
      setDocumentosCargados(true);
    }
  }, []);

  return {
    setVisible,
    visible,
    closeModal,
    openModal,
    loading,
    uploadProductImageRut,
    uploadProductImageCedula1,
    uploadProductImageCedula2,
    saveUploadDocument,
    storeDocuments
  }
}

type StoreDocuments = {
  id?: number,
  storeId?: number
  rut?: string,
  cedula1?: string,
  cedula2?: string,
  created_at?: Date,
  updated_at?: Date
}
