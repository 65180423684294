import { Modal } from 'antd';
import moment from 'moment';
import React, { useState,useContext, useEffect } from 'react'
import { axiosInstance } from '../../commons/services/axiosConfig';
import { Bill, mySells } from '../../Models/Bill';
import { PageContext } from '../ContextProvider';

interface IMySales {
    storeId:number,
    from:string,
    to:string
}

export const useSales = () => {

    const {userData} = useContext(PageContext);
    const [salesList, setSalesList] = useState<mySells[]>([]);
    const [loading, setLoading] = useState(false);
    const [filterStateSelected, setFilterStateSelected] = useState<'Pendiente' | 'Anulado' | 'Despachado' | 'Devuelto' | 'Adelantado' | 'Pagado' | 'Entregado' | 'Novedad' | 'Despachado' | 'Solucionada' | 'Todos'>("Todos");
    const [salesFilters, setSalesFilters] = useState<IMySales>({
        storeId:userData ? userData.storeID : 78,
        from:moment(new Date).format('YYYY-MM-DD'),
        to:moment(new Date).format('YYYY-MM-DD')
    });
    const [modalSearchGuiaVisible, setModalSearchGuiaVisible] = useState<boolean>(false);
    const [guia, setGuia] = useState<string>("");

    useEffect(() => {
       getSales();
       if ( userData?.storeID) {
       getBillNews(userData?.storeID);
       }
    }, [salesFilters]);


    const handleChangeDates = (e: any) => {
        if(e != null)
        {
            setSalesFilters({
                ...salesFilters,
                from:moment(e[0]).format('yyyy-MM-DD hh:mm:ss'),
                to:moment(e[1]).format('yyyy-MM-DD hh:mm:ss')
            })
        }
    }

    function warningNotification(numBill: number, name: string) {
        Modal.warning({
          title: `Hola! ${name} recuerda dar solución a tus novedades el día de hoy! `,
          content: `Tienes (${numBill}) novedades. Recuerda que de la solución efectiva de tus novedades depende la entrega de tus pedidos y evitas gastos innecesarios de fletes de devolución`
        });
      }
    
      const dateFormat = 'YYYY/MM/DD';
    
      const getBillNews = (id: number) => {
        axiosInstance.get<Bill[]>(`/Bills/${id}`).then((res) => {
          let to = moment(new Date()).format(dateFormat);
          let from = moment(to).subtract(1, 'months').format(dateFormat)
          let newsBill = res.data.filter((x: Bill) => moment(x.created_at).format(dateFormat) >= from &&
            moment(x.created_at).format(dateFormat) <= to).filter((x: Bill) => x.status == 'Novedad')
          if (newsBill.length > 0) {
            if ( localStorage.storeID !== "" ) {
                axiosInstance.get(`/Stores/${userData?.storeID}`).then((res) => {
                let store = res.data.name;
                warningNotification(newsBill.length, store);
              })
            }
          }
        }).catch(err => {
          console.log(err);
        })
      }


    const getSales = async () => {
        setLoading(true);
        const rest = await axiosInstance.get<mySells[]>('/StoreClient/GetMysales?storeId='+salesFilters.storeId+'&from='+salesFilters.from+'&to='+salesFilters.to);
        if(rest.status == 200)
        {
            rest.data.map(x=>x.created_at = moment(x.created_at).format('yyyy-MM-DD hh:mm:ss'));
            if(localStorage.currency == 'USD')
            {
                setSalesList(rest.data.filter(x=>x.countryId == 3));
            }
            else{
                setSalesList(rest.data.filter(x=>x.countryId == 1));
            }
            setLoading(false);
        }
    }

    const filtrarPorEstado = (status: 'Pendiente' | 'Anulado' | 'Despachado' | 'Devuelto' | 'Adelantado' | 'Pagado' | 'Entregado' | 'Novedad' | 'Despachado' | 'Solucionada' | 'Todos') => {
        setFilterStateSelected(status)
      }

      const findGuia = (data: mySells) => {
        if(data.company_Transport == 'Paloma')
        {
          window.open(
            `https://web.palomamensajeria.com/DetailBill?guia=${data.guia}`,
            "_blank"
          );
        }
        else{
          setGuia(data.guia)
          setModalSearchGuiaVisible(true);
        }
      }

  return {
    loading,
    filterStateSelected,
    salesList,
    filtrarPorEstado,
    handleChangeDates,
    guia,
    modalSearchGuiaVisible,
    setModalSearchGuiaVisible,
    findGuia
  }
}
