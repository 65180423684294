import { useRef, useState } from 'react'
import moment from "moment";
import { Warehouse, WarehouserData } from '../../Models/warehouse.model';
import { EyeOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { company_transport } from '../../Models/company_Transport';
import { Inventory } from '../../Models/Products';
import { user } from '../../Models/SuperAdmin/users.model';
import { Bill, BillData, vw_Bills } from '../../Models/Bill';
import { axiosInstance } from '../../commons/services/axiosConfig';

export const useCompanyTransportWarehouse = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [ListBills, setListBills] = useState<Array<vw_Bills>>([]);
    const [ListBillsAnulationRequest, setListBillsAnulationRequest] = useState<Array<vw_Bills>>([]);
    const [warehouseData, setWarehouseData] = useState<WarehouserData>();
    const [selectedProgramadosList, setSelectedProgramadosList] = useState<Array<vw_Bills>>([]);
    const [companyTransportList, setCompanyTransportList] = useState<company_transport[]>([]);
    const [selectedComanyTransport, setSelectedComanyTransport] = useState<any>();
    const [selectedCompanyTransport, setSelectedCompanyTransport] = useState("");
    const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState<number>();
    const [inventoryList, setInventoryList] = useState<Inventory[]>([]);
    const [modalSearchGuiaVisible, setModalSearchGuiaVisible] = useState<boolean>(false);
    const [guia, setGuia] = useState<string>("");
    const [ListRouteBills, setListRouteBills] = useState<Array<vw_Bills>>([]);
    const [selectedRouteDate, setSelectedRouteDate] = useState<string>("");
    const [selectedCourrier, setselectedCourrier] = useState<string>("");
    const [CourrierList, setCourrierList] = useState<user[]>([]);
    const [modalPrintRouteVisible, setModalPrintRouteVisible] = useState<boolean>(false);
    const [data, setData] = useState<BillData[]>([]);
    const [pendingBills, setPendingBills] = useState<any[]>([]);
    const [pendingBillsGuia, setPendingBillsGuia] = useState<Bill[]>([]);
    const [pendingAnulationBills, setPendingAnulationBills] = useState<Bill[]>([])
    const [warehouse, setWarehouse] = useState<WarehouserData[]>([]);
    const [selectedTab, setSelectedTab] = useState("1")
    const inputRef = useRef<any>();


    const getWarehouseList = () => {
        setLoading(true);
        axiosInstance.get(`/Warehouses/GetWarehouseList`).then(res => {
            setWarehouse(res.data);
            setLoading(false);
        })
    }

    const GetBillsByStatusAndUserWarehouseId = (status: string, warehouseId: number) => {
        setLoading(true);
        axiosInstance.get(`/Bills/GetBillsByStatusAndUserWarehouseId?WarehouseId=${warehouseId}&Status=${status}`).then(res => {
            setListBills(res.data);
            setLoading(false);
            GetAnulationRequestBills('Anulación Solicitada', warehouseId);
        })
    }

    const GetPendingBills = async () => {
        setLoading(true);
        let bills = await axiosInstance.get(`/Bills/GetBillsByStatusAndUserWarehouseId?Status=Pendiente`);
        let pendingBillsGuias = await axiosInstance.get(`/Bills/GetBillsByStatusAndUserWarehouseId?Status=Impreso-R`);
        let pendingAnulationBills = await axiosInstance.get(`/Bills/GetBillsByStatusAndUserWarehouseId?Status=Anulación Solicitada`);
        setPendingBills(bills.data);
        setPendingBillsGuia(pendingBillsGuias.data);
        setPendingAnulationBills(pendingAnulationBills.data);
    }

    const GetAnulationRequestBills = (status: string, warehouseId: number) => {
        setLoading(true);
        axiosInstance.get(`/Bills/GetBillsByStatusAndUserWarehouseId?WarehouseId=${warehouseId}&Status=${status}`).then(res => {
            setListBillsAnulationRequest(res.data);
            setLoading(false);
        })
    }

    const onChangeSelectedProgramadosList = ({ selectedRowKeys, selectedRowsData }: any) => {
        setSelectedProgramadosList(selectedRowKeys)
    }

    const callback = (key: string) => {
        setSelectedTab(key);
    }

    const updatePrintedLabelBills = async () => {
        try {
            let arrayToUpdate = selectedProgramadosList.map(x => {
                let item = pendingBills.find((item: Bill) => item.id === x.id);
                let estado: any = 'Impreso-R';
                if (x.company_Transport === "Envia" && x.status === 'Pendiente') {
                    estado = 'Impreso-R';
                } else {
                    estado = 'Despachado';
                }
                let el = {
                    id: x.id,
                    status: estado,
                    guia: x.guia,
                    delivery_value: item?.delivery_value,
                    payment_home: item?.payment_home,
                    collect_value: item?.collect_value,
                    authorized: item?.authorized,
                    store_ClientId: item?.store_ClientId,
                    storeId: item?.storeId,
                    origin_city: item?.origin_city,
                    anulation_comment_id: item?.anulation_comment_id,
                    company_TransportId: item?.company_TransportId,
                }
                return el;
            });
            axiosInstance.put(`/Bills`, arrayToUpdate).then(res => {
            })
                .catch(err => console.error(err))
        } catch (error) {
            console.log(error);
        }
    }

    const printLabels = () => {
        setLoading(true);
        let arrayIds = selectedProgramadosList.map(x => {
            return x.id;
        });
        axiosInstance.get(`/Bills/DownloadBillsShippingGuideReport/${arrayIds}`).then((res: any) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
            const blobUrl = window.URL.createObjectURL(pdfBlob);
            window.open(blobUrl);
            updatePrintedLabelBills();
            GetPendingBills();
            setLoading(false);
        });
    }

    const printenviaShippingGide = () => {
        let arrayIds = selectedProgramadosList.map(x => {
            return x.id;
        })
        axiosInstance.get(`/Bills/DownloadBillsShippingGuideReport/${arrayIds}`).then((res: any) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
            const blobUrl = window.URL.createObjectURL(pdfBlob);
            window.open(blobUrl);
            updatePrintedLabelBills();
            GetPendingBills();
        })
            .catch(res => {
                message.error('Hubo un error al intentar generar el reporte. Todas las facturas deben ser de la empresa transportadora Envia y tener un número de guía', 8);
                // setLoading(/);
            })
    }

    const getCompanyTransportList = async () => {
        setLoading(true);
        let res = await axiosInstance.get(`/CompanyTransports`)
        setCompanyTransportList(res.data);
        setLoading(false);
    }

    const onchangeCompanyTransport = (item: number) => {
        setSelectedComanyTransport(item);
        getWarehouseListList(item);
    }

    const getWarehouseListList = (companyTransportId: number) => {
        setLoading(true);
        axiosInstance.get(`/Warehouses/${companyTransportId}`).then(res => {
            setLoading(false);
            setWarehouseList(res.data);
        })
    }

    const cellRenderView = (row: any) => {
        return <EyeOutlined onClick={() => { setGuia(row.data.guia); setModalSearchGuiaVisible(true); }} />
    }

    const handleChangeRouteDate = (date: any, dateString: any) => {
        setSelectedRouteDate(dateString);
        if (dateString && selectedCourrier) {
            GetRouteListByCourrierAndDate(selectedCourrier, dateString);
        }
    }

    const handleChangeCourrier = (courrier: string) => {
        setselectedCourrier(courrier);
        if (selectedRouteDate && courrier) {
            GetRouteListByCourrierAndDate(courrier, selectedRouteDate);
        }
    }

    const GetRouteListByCourrierAndDate = (courrier: string, date: string) => {
        setLoading(true);
        axiosInstance.get(`/Dispatchs/GetRouteListByCourrierAndDate?Courrier=${courrier}&date=${date}`).then(res => {
            setListRouteBills(res.data);
            setLoading(false);
        })
    }

    const GetCourrierList = (companyTransportId: number) => {
        setLoading(true);
        axiosInstance.get(`/Users/GetCurriersByCompanyTransport?companyTransportId=${companyTransportId}`).then(res => {
            setCourrierList(res.data);
            setLoading(false);
        });
    }

    const addItemToArray = (e: any) => {
        if (e.key === 'Enter') {
            let guia = e.target.value;
            axiosInstance.get(`/Bills/GetBillByGuia/${guia}`).then(res => {
                if (res.data !== "") {
                    let alreadyExist = data.filter(x => x.guia === res.data.guia);
                    if (alreadyExist.length === 0) {
                        res.data.created_at = moment(res.data.created_at).utcOffset('GMT-05').format('YYYY-MM-DD HH:mm')
                        setData(oldArray => [...oldArray, res.data])
                    }
                    else {
                        message.warning("Esta guia ya fue incluida en la relación");
                    }
                }
                else {
                    message.error('Este número de guia no existe');
                }
            })
            inputRef.current.focus();
            setGuia("");
        }
    }

    const dispatchBills = () => {
        let arrayBills: any = [];
        data.map(x => {
            let el = {
                route: selectedCourrier,
                billingId: x.id
            }
            arrayBills.push(el);
        });
        axiosInstance.post(`/Bills/DispatchWarehouse`, data).then(x => {
            message.success("Las guias fueron asignadas");
            setData([]);
        });
    }

    return {
        loading,
        getWarehouseList,
        GetBillsByStatusAndUserWarehouseId,
        ListBills,
        warehouseData,
        onChangeSelectedProgramadosList,
        selectedProgramadosList,
        printLabels,
        getCompanyTransportList,
        companyTransportList,
        setSelectedCompanyTransport,
        selectedComanyTransport,
        onchangeCompanyTransport,
        warehouseList,
        selectedWarehouse,
        inventoryList,
        ListBillsAnulationRequest,
        modalSearchGuiaVisible,
        setModalSearchGuiaVisible,
        guia,
        cellRenderView,
        ListRouteBills,
        handleChangeRouteDate,
        handleChangeCourrier,
        CourrierList,
        modalPrintRouteVisible,
        setModalPrintRouteVisible,
        selectedCourrier,
        selectedRouteDate,
        addItemToArray,
        inputRef,
        setGuia,
        setData,
        data,
        dispatchBills,
        selectedCompanyTransport,
        pendingBills,
        GetPendingBills,
        warehouse,
        pendingBillsGuia,
        printenviaShippingGide,
        callback,
        selectedTab,
        pendingAnulationBills
    }
}