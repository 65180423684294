import {
  CaretDownOutlined,
  CaretUpOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Popconfirm,
  Row,
  Spin,
  Statistic,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { PageContext } from "../../Hooks/ContextProvider";
import {
  storeWalletMovementFast,
  store_walletDetail,
} from "../../Models/store_wallet.model";
import { IModalStoreWalletDetailProps } from "./IModalStoreWalletDetailProps";
import "./ModalStoreWalletDetail.css";

const ModalStoreWalletDetail = (props: IModalStoreWalletDetailProps) => {
  const pageSizes = [10, 25, 50, 100];

  const [historyMovementDetail, setHistoryMovementDetail] = useState<
    storeWalletMovementFast[]
  >([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saldoTotal, setSaldoTotal] = useState<number>(0);

  const { userData } = useContext(PageContext);

  useEffect(() => {
    setSaldoTotal(props.total);
    getStoreWalletHistory();
  }, [props.total]);

  const adelantarDinero = () => {
    setLoading(true);
    let array = historyMovementDetail
      .filter((x) => x.movement_type === "Disponible Adelanto")
      .map((x) => {
        let el: any = {
          id: x.billingId,
          status: "Adelantado",
        };
        return el;
      });
    axiosInstance.put(`/Bills`, array)
      .then((res) => {
        message.success("El saldo fue adelantado");
        setLoading(false);
      })
      .catch((err) => {
        message.error("Se presentó un error, intentalo nuevamente");
        let email: any = {
          mailTo: "david.buitrago@zarus.co",
          subject: "Error al Adelantar saldo",
          body: JSON.stringify(err),
        };
        axiosInstance.post(`/Mail`, email);
        setLoading(false);
      });
  };

  const getStoreWalletHistory = async () => {
    let res = await axiosInstance.get(
      `/StoreWallet/${localStorage.currency == 'COP' ? 'GetStoreWallets' : 'GetUSDStoreWallets'}?storeId=${userData?.storeID}`
    );
    res.data.map((item: any) => {
      item.created_at = moment(item.created_at)
        .utcOffset("GMT-05")
        .format("YYYY-MM-DD HH:mm");
    });
    if (props.movementType == "Saldo total") {
      setHistoryMovementDetail(
        res.data.filter(
          (x: storeWalletMovementFast) =>
            x.movement_type != "Disponible Adelanto"
        )
      );
    } else {
      setHistoryMovementDetail(
        res.data.filter(
          (x: storeWalletMovementFast) => x.movement_type == props.movementType
        )
      );
    }
  };

  const columns = [
    {
      title: "# Factura",
      dataIndex: "billingId",
      key: "billingId",
    },
    {
      title: "Guia",
      dataIndex: "guia",
      key: "guia",
    },
    {
      title: "Tipo de movimiento",
      dataIndex: "movement_type",
      key: "movement_type",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (f: any, x: store_walletDetail) => {
        if (x.value > 0) {
          return (
            <span style={{ color: "green" }}>
              <CaretUpOutlined />{" "}
              {`${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          );
        }
        if (x.value < 0) {
          return (
            <span style={{ color: "red" }}>
              <CaretDownOutlined />{" "}
              {`${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          );
        }
        if (x.shippingTotal) {
          return (
            <span style={{ color: "red" }}>
              <CaretDownOutlined />{" "}
              {`${x.shippingTotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          );
        }
      },
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Detalle",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <div className={`modalStoreWalletDetail`}>
      <div className="generic-btn" onClick={() => setVisible(true)}>
        Ver Detalle
      </div>
      <Modal
        open={visible}
        width={1200}
        title={
          <Row>
            <Col
              span={14}
            >{`Detalle de movimiento - ${props.movementType}`}</Col>
            <Col span={4}>
              {props.movementType === "Disponible Adelanto" && (
                <Popconfirm
                  onConfirm={() => adelantarDinero()}
                  title={`¿ Al adelantar este saldo, se descontará el 1.5 % + IVA del valor adelantado ?`}
                  okText="Adelantar"
                  cancelText="Cancelar"
                  disabled={saldoTotal == 0}
                >
                  <Button
                    loading={loading}
                    type="primary"
                    disabled={saldoTotal == 0 || historyMovementDetail
                      .filter((x) => x.movement_type === "Disponible Adelanto").length <= 0}
                  >
                    <DollarCircleOutlined /> Adelantar
                  </Button>
                </Popconfirm>
              )}
            </Col>

            <Col span={4}>
              <Statistic
                valueStyle={{ color: "#3f8600" }}
                value={saldoTotal.toFixed(2)}
                prefix={"$"}
              />
            </Col>
            <Col span={1}>
              <Statistic value={localStorage.currency} valueStyle={{ color: "#3f8600" }} />
            </Col>
          </Row>
        }
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cerrar
          </Button>,
        ]}
      >
        <Spin tip="Cargando..." spinning={loading}>
          <DataGrid
            dataSource={historyMovementDetail}
            allowColumnReordering={true}
            showBorders={true}
            rowAlternationEnabled={true}
          // onSelectionChanged={selectedRows}
          >
            <HeaderFilter visible={true} />
            <Export enabled={true} allowExportSelectedData={false} />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            <Column
              dataField="billingId"
              dataType="string"
              caption="# Factura"
            />
            <Column dataField="guia" caption="Guia" dataType="string" />
            <Column
              dataField="movement_type"
              dataType="string"
              caption="Tipo de movimiento"
            />
            <Column
              dataField="value"
              cellRender={valueCustom}
              caption="Valor"
            />
            {/* <Column dataField="shippingTotal" dataType="number" caption="Flete" /> */}
            <Column
              dataField="created_at"
              dataType="string"
              caption="Fecha"
              format="yyyy-MM-dd HH:mm"
            />
            <Column
              dataField="description"
              dataType="string"
              caption="Detalle"
              width={515}
            />

            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
            <Paging defaultPageSize={20} />
          </DataGrid>
        </Spin>
        {/* <Table dataSource={historyMovementDetail} columns={columns} loading={loading} /> */}
      </Modal>
    </div>
  );
};

function valueCustom({ data }: any) {
  if (data.value > 0) {
    return (
      <span style={{ color: "green" }}>
        <CaretUpOutlined />{" "}
        {`${data.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
    );
  }
  if (data.value < 0) {
    return (
      <span style={{ color: "red" }}>
        <CaretDownOutlined />{" "}
        {`${data.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
    );
  }
  if (data.shippingTotal) {
    return (
      <span style={{ color: "red" }}>
        <CaretDownOutlined />{" "}
        {`${data.shippingTotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
    );
  }
}

export default ModalStoreWalletDetail;
