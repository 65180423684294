import React, { useEffect, useState } from 'react'
import moment from 'moment';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// API

// Models
import { message } from 'antd';
import { axiosInstance } from '../../../../../commons/services/axiosConfig';

const useWarehouseDispatchReport = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [dispatchReport, setDispatchReport] = useState([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const dateFormat = 'YYYY/MM/DD';

    const getDispatchReport = async (date: any) => {
        setLoading(true)
        try {
            const resp = await axiosInstance.get(`/Bills/GenerateWarehouseDispatchReport?date=${date}`);
            const arrayBills = resp.data.filter((x: any) => x.transportadora === 'Crecel' || x.transportadora === 'Crecel Ecuador')
            setLoading(false)
            setDispatchReport(arrayBills);
        } catch (error) {
            message.error(`No se encontró relación de guias ${error}`);
        }
    }

    const onChangeDates = (date: any) => {
        let selectDate = moment(date).utcOffset('GMT-05').format(dateFormat)
        getDispatchReport(selectDate);
    }

    function printDiv() {
        const pdf = new jsPDF("p", "mm", "a4");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        var position = 0;
        let input: any = document.getElementById(`divToPrint`);
        html2canvas(input)
            .then((canvas) => {
                let imgData: any = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'JPEG', 0, position, width, 0);
                position = position + height;
                let string: any = pdf.output('bloburl')
                window.open(string, '_blank');
            }
            );
    }

    window.onafterprint = function () {
        window.location.reload();
    }

    const imprimirRuta = () => {
        printDiv_('divToPrint');
    };

    function printDiv_(elemento: any) {
        var divElements = document.getElementById(elemento)?.innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML =
            "<html><head><title></title></head><body>" +
            divElements +
            "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
    }

    return {
        loading,
        onChangeDates,
        dispatchReport,
        setModalVisible,
        modalVisible,
        imprimirRuta
    }
}

export default useWarehouseDispatchReport
