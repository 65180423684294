import { Button, Col, Divider, Modal, Progress, Row, Spin, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { IPrintModalDispatchRelationshipProps } from './IPrintDispatchRelationshipProps';
import styles from './PrintDispatchRelationship.module.scss';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { company_transport } from '../../../../Models/company_Transport';
import { updateBill } from '../../../../Hooks/SuperAdmin/useEditBill';
import { Bill } from '../../../../Models/Bill';
import { BillProducts, product } from '../../../../Models/Products';
import { axiosInstance } from '../../../../commons/services/axiosConfig';



const PrintDispatchRelationShip = (props:IPrintModalDispatchRelationshipProps) => {

  const [companyTransportList, setCompanyTransportList] = useState<company_transport[]>([]);

  useEffect(() => {
    GetCompanyTransport();
  }, []);

  const GetCompanyTransport = () => {
    axiosInstance.get<company_transport[]>(`/CompanyTransports`).then(res => {
      setCompanyTransportList(res.data);
    });
  }

  const updatePrintedLabelBills = () => {
    let arrayToUpdate: updateBill[] = props.dispatchBills.map((item: Bill) => {
      let el: updateBill = {
        id: item?.id !== undefined ? item.id : 0,
        status: 'Despachado',
        guia: item.guia,
        delivery_value: item?.delivery_value,
        payment_home: item?.payment_home,
        collect_value: item?.collect_value,
        authorized: item?.authorized,
        store_ClientId: item?.store_ClientId,
        storeId: item?.storeId,
        origin_city: item?.origin_city,
        anulation_comment_id: item?.anulation_comment_id,
        company_TransportId: item?.company_TransportId,
      }
      return el;
    })
    axiosInstance.put(`/Bills`, arrayToUpdate).then(res => {
      console.log(res.data);
    });
  }


  function printDiv() {
    const pdf = new jsPDF("p", "mm", "a4");
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var position = 0;
    let input: any = document.getElementById(`divToPrint`);
    html2canvas(input)
      .then((canvas) => {
        let imgData: any = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'JPEG', 0, position, width, 0);
        position = position + height;
        let string: any = pdf.output('bloburl')
        window.open(string, '_blank');
        updatePrintedLabelBills();
      }
      );
  }

  window.onafterprint = function () {
    window.location.reload();
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'numero',
      key: 'numero'
    },
    {
      title: 'Guia',
      dataIndex: 'guia',
      key: 'guia'
    },
    {
      title: 'Teléfono',
      dataIndex: 'guia',
      key: 'guia',
      render: (f: any, x: Bill) => {
        return `${x.storeClient?.cellphone} / ${x.storeClient?.phone}`
      }
    },
    {
      title: 'Contenido',
      dataIndex: 'bill_Products',
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let arrayProds = x.bill_Products.map((prod: BillProducts) => {
          let productFiltered = props.productList.filter((p: product) => prod.productId === p.id);
          return productFiltered.length > 0 ? `${prod.quantity} ${productFiltered[0].name}` : ""
        });
        return arrayProds.join();
      }
    }
  ];

  const imprimirRuta = () => {
    printDiv_('divToPrint');
  };

  function printDiv_(elemento:any) {

    var divElements = document.getElementById(elemento)?.innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;
    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
      "<html><head><title></title></head><body>" +
      divElements +
      "</body>";
    //Print Page
    window.print();
    //Restore orignal HTML
    document.body.innerHTML = oldPage;
  }


  return (
    <div>
      <Modal
        width={1000}
        open={props.ModalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => {
          props.setModalVisible(false);
        }}
        footer=
        {[
          <Button key="submit" type="primary" onClick={() => props.setModalVisible(false)}> Cerrar </Button >
        ]}
      >
        <Button onClick={imprimirRuta}>Imprimir</Button>
        <div id='divToPrint' style={{ textAlign: 'center' }}>
          <Row style={{ marginTop: 30 }}>
            <Col span={24}>
              <h1>RELACIÓN DE DESPACHOS</h1>
            </Col>
          </Row>
          <Divider />
          <Row style={{ textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
            <Col span={2}></Col>
            <Col span={8}>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col span={12}>
                  Relación Nº:
                    </Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>
                  {props.dispatchData?.id}
                </Col>
              </Row>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col span={12}>
                  Fecha de creación:
                    </Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>
                  {moment(props.dispatchData?.created_at).format("YYYY-MM-DD HH:mm")}
                </Col>
              </Row>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col span={12}>
                  Dirección de recogída:
                    </Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>
                  Carrera 50 FF # 10 sur 150
                    </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col span={12}>
                  Transportadora:
                    </Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>
                  {companyTransportList.length > 0 && props.dispatchData !== undefined &&
                    companyTransportList.filter((x: company_transport) => x.id == props.dispatchData?.bills[0].company_TransportId)[0].name
                  }
                </Col>
              </Row>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col span={12}>
                  Ciudad de recogída:
                    </Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>
                  Medellín (Antioquia)
                    </Col>
              </Row>
              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col span={12}>
                  Total guias:
                    </Col>
                <Col span={12} style={{ fontWeight: 'bold' }}>
                 {props.dispatchBills.length}
                    </Col>
              </Row>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Divider />
          
          <Table columns={columns} dataSource={props.dispatchBills} pagination={{ pageSize: 1000 }} />

        </div>
      </Modal>
    </div>

  );
};

export default PrintDispatchRelationShip;
