import { Button, Col, message, Modal, Row } from 'antd';
import React, {useState,useEffect} from 'react';
import { IModalCreateDeliveryNewsProps } from './IModalCreateDeliveryNewsProps';
import './ModalCreateDeliveryNews.css';
import {deliveryNews} from '../../Models/SuperAdmin/deliveryNews.model'
import { Input } from 'antd';
import { axiosInstance } from '../../commons/services/axiosConfig';
import { updateBill } from '../../Hooks/SuperAdmin/useEditBill';


const { TextArea } = Input;

const ModalCreateDeliveryNews = (props:IModalCreateDeliveryNewsProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [deliveryNews, setDeliveryNews] = useState<deliveryNews>({
    description: "",
    state: "Pendiente",
    created_at: "",
    updated_at: "",
    billingId: props.billingId,
    delivery_News_TypeId: 1,
    created_by: parseInt(localStorage.userID),
    response: ""
  });

  const closeModal = () => {
    setVisible(false);
  }
  
  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setDeliveryNews(prevState => ({
        ...prevState,
        [name]: value
    }));
};

const saveData = () => {
  setLoading(true);
  axiosInstance.post(`/DeliveryNews`, deliveryNews).then(res=>{
    estadoNovedad();
  })
}

const estadoNovedad = () => {
      props.billData.status= "Novedad";
      let array: updateBill[] = [];
      let el: any = props.billData;
      array.push(el);
      axiosInstance.put(`/Bills`, array).then(res => {
        message.success('Los datos fueron guardados');
        closeModal();
        setLoading(false);
      })
}

  return (
    <div className={`modalCreateDeliveryNews`}>
      <Row>
        <Col span={24}>
          <Button type='primary' onClick={() => setVisible(true)}>Registrar Novedad</Button>
        </Col>
      </Row>
      <Modal
        open={visible}
        title={`Registrar novedad Guia ${props.guia}`}
        width={1000}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
      >
        <div style={{padding:20}}>
        Novedad:
        <TextArea 
          rows={4} 
          name="description"
          onChange={(e)=>handleChange(e)}
          value={deliveryNews.description}
        />
        <br/><br/>
        <Row>
        <Col span={17}></Col>
        <Col span={3}>
        <Button key="back" onClick={() => closeModal()}>
            Cerrar
          </Button>
        </Col>
        <Col span={1}></Col>
        <Col span={3}>
        <Button type="primary" key="back" onClick={() => saveData()}>
              Guardar
            </Button>
        </Col>
        </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateDeliveryNews;
