import { notification } from "antd";
import { useState } from "react";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { deliveryNews } from "../../Models/SuperAdmin/deliveryNews.model";
import { vw_bills } from "../../Models/SuperAdmin/vw_bills.model";

export interface updateBill {
  id: number,
  status?: 'Pendiente' | 'Anulado' | 'Despachado' | 'Devuelto' | 'Adelantado' | 'Pagado' | 'Entregado' | 'Novedad' | 'Impreso-G' | 'Impreso-R' | 'Solucionada',
  guia?: string,
  delivery_value?: number,
  payment_home?: boolean,
  collect_value?: number,
  authorized?: boolean,
  store_ClientId?: number,
  storeId?: number,
  origin_city?: number,
  anulation_comment_id?: number,
  company_TransportId?: number,
}

export const useEditBill = () => {

  const [billData, setBillData] = useState<vw_bills>();
  const [loading, setLoading] = useState<boolean>(false);
  const [billUpdateData, setbillUpdateData] = useState({
    id: 0,
    guia: '',
    delivery_value: 0,
    platformUse: 0,
    collectionCommission: 0
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deliveryNews, setDeliveryNews] = useState<deliveryNews>({
    description: "",
    state: "Pendiente",
    billingId: 0,
    delivery_News_TypeId: 1,
    created_by: 9,
    response: ""
  });
  const [cancelObservations, setCancelObservations] = useState("");

  const getBillData = (billId: number) => {
    axiosInstance.get(`/Bills/GetbillById?billId=${billId}`).then(res => {
      setBillData(res.data);
      setbillUpdateData({ 
        guia: res.data.guia, 
        delivery_value: res.data.delivery_value,
        id: res.data.id, 
        platformUse: res.data.platformUse,
        collectionCommission: res.data.collectionCommission 
      });
    });
  }

  const saveDeliveryNews = (billId: number) => {
    deliveryNews.billingId = billId;
    axiosInstance.post('/Bills/Delivery_News',deliveryNews).then(res => {
      notification.success({
        message: "Novedad registrada",
        description: "La novedad se registró correctamente",
        placement: "bottomRight"
      });
      setIsModalVisible(false);
    });
  }

  const handleDeliveryNewsChange = (text:"") => {
    setDeliveryNews(prevState => ({
        ...prevState,
        description: text
    }));
};

  const handleChangeBillData = (e: any,field?:any) => {
    if(e.name)
    {
      const { name, value } = e.target;
      setbillUpdateData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else{
      setbillUpdateData(prevState => ({
        ...prevState,
       [field]: e
    }));
  }
}

  const saveData = () => {
    setLoading(true);
    let array: updateBill[] = [];
    array.push(billUpdateData);
    axiosInstance.put('/Bills',array).then(res => {
      notification.success({ message: 'La guia fue actualizada correctamente' });
      setLoading(false);
    })
  }

  const ReturnBill = (changeFreight: boolean,billId:number) => {
    setLoading(true);
    let el = {
      billId: billId,
      chargeFreight: changeFreight,
      observaciones_Cancelacion: cancelObservations
    }
  axiosInstance.put('Bills/ReturnBill',el).then(res=>{
      notification.success({ message: 'La Guia fue devuelta'});
      setLoading(false);
    });
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    handleDeliveryNewsChange("");
  };

  return {
    getBillData,
    billData,
    saveData,
    loading,
    handleChangeBillData,
    billUpdateData,
    showModal,
    handleClose,
    isModalVisible,
    saveDeliveryNews,
    deliveryNews,
    handleDeliveryNewsChange,
    cancelObservations,
    setCancelObservations,
    ReturnBill
  }
}