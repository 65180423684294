import React, {useState,useEffect, useContext} from 'react';
import { IUpdateBankStoreDataProps } from './IUpdateBankStoreDataProps';
import './UpdateBankStoreData.css';
import Modal from 'antd/lib/modal/Modal';
import { Row, Input, Col, Select, message, Button } from 'antd';
import utils from '../../utils';
import { bank } from '../../Models/bank.model';
import { axiosInstance } from '../../commons/services/axiosConfig';
import { PageContext } from '../../Hooks/ContextProvider';
const { Option } = Select;

const UpdateBankStoreData = (props:IUpdateBankStoreDataProps) => {

  const [listBanksFiltered, setListBanksFiltered] = useState<Array<bank>>([]);
  const [listBanks, setListBanks] = useState<Array<bank>>([]);
  const [loading, setLoading] = useState(false);

  const {
    userData,
} = useContext(PageContext);


  useEffect(() => {
    getBanks();
  }, []);

  const getBanks = () => {
    axiosInstance.get(`/Banks`).then(res => {
      setListBanks(utils.sortArray(res.data, 'name'));
      setListBanksFiltered(utils.sortArray(res.data, 'name'));
    })
  }

  const handleOk = () => {
    setLoading(true);
    if(props.store_bank.id)
    {
      axiosInstance.put(`/Store_Banks/${props.store_bank.id}`, props.store_bank).then(res=>{
        message.success("Los datos de la cuenta fueron actualizados existosamente",5);
        props.setUpdateBankStoreDataVisible(false);
        setLoading(false);
      })
      .catch(err=>{message.error('Se presentó un error al actualizar la tienda, por favor intentalo nuevamente',5);setLoading(false)})
    }
    else{
      let el:any = props.store_bank;
      el.StoreId = userData?.storeID;
      axiosInstance.post(`/Store_Banks`,el).then(resp => {
        message.success("Los datos bancarios fueron actualizados existosamente",5);
        props.setUpdateBankStoreDataVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        message.warning(err.response.data);
        console.log(err);
        setLoading(false);
      });
    }
  }

  const handleSearchBank = (value: string) => {
    if (value) {
      setListBanksFiltered(listBanks.filter((x: bank) => x.name.toLowerCase().includes(value)));
    } else {
      setListBanksFiltered([])
    }
  }

  const updateState = (value: any, prop: string) => {
    let arrayAux = { ...props.store_bank as any }
    arrayAux[prop] = value;
    props.setStore_bank(arrayAux);
  }

  const handleCancel = () => {
    props.setUpdateBankStoreDataVisible(false);
  }
  return (
    <div className={`updateBankStoreData`}>
      {props.store_bank !== undefined &&
      <Modal
          title="Actualizar datos del banco"
          open={props.updateBankStoreDataVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={loading}
        >
           <div style={{padding:10}}>
          <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <b>Banco:</b>
          <Select
            style={{ width: '100%' }}
            showSearch
            defaultValue={props.store_bank.bankId}
            placeholder="Banco"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchBank}
            onChange={(e) => updateState(e, 'bankId')}
            notFoundContent={null}
          >
            {listBanksFiltered.map((x: bank) => <Option key={x.id} value={x.id}>{x.name}</Option>)}
          </Select>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <b>Tipo Cuenta:</b>
          <Select value={props.store_bank.account_type} style={{ width: '100%' }} onChange={(e) => updateState(e, 'account_type')}>
            <Option key='cuentaAhorros' value={'Cuenta de ahorros'}>Cuenta de ahorros</Option>
            <Option key='cuentaCorriente' value={'Cuenta de corriente'}>Cuenta de corriente</Option>
          </Select>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <b>Número de cuenta:</b>
          <Input value={props.store_bank.bank_account} onChange={(e) => updateState(e.target.value, 'bank_account')}/>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <b>Nombre titular de la cuenta:</b>
          <Input value={props.store_bank.owner_account} onChange={(e) => updateState(e.target.value, 'owner_account')}/>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <b>Número de cédula:</b>
          <Input value={props.store_bank.owner_identification} onChange={(e) => updateState(e.target.value, 'owner_identification')}/>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <b>Global 66:</b>
          <Input value={props.store_bank.global66} onChange={(e) => updateState(e.target.value, 'global66')}/>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <b>PayPal:</b>
          <Input value={props.store_bank.payPal} onChange={(e) => updateState(e.target.value, 'payPal')}/>
        </Col>
      </Row>
      <Row>
            <Col span={20}></Col>
            <Col span={4}>
              <Button onClick={handleOk} className='generic-btn'>Guardar</Button>
            </Col>
          </Row>
      </div>
        </Modal>
}
    </div>
  );
};

export default UpdateBankStoreData;
