import { message } from "antd";
import { useContext, useEffect, useState } from "react"
import { axiosInstance } from "../../../commons/services/axiosConfig";
import { PageContext } from "../../../Hooks/ContextProvider";
import { BillData } from "../../../Models/Bill";
import { BillProducts, product } from "../../../Models/Products";



export const useSearchByPhone = () => {

    const [billList, setBillList] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [productsList, setProductsList] = useState<product[]>([]);
    const [modalSearchGuiaVisible, setModalSearchGuiaVisible] = useState<boolean>(false);
    const [guia, setGuia] = useState<string>("");
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const { userData } = useContext(PageContext);
    
    useEffect(() => {
      getProducts();
    }, [])

    const showModal = () => {
      setModalVisible(true);
    }

    // Buscador por numero de celular 
    const SearchBill = async (phone: string) => { 
        setLoading(true);
        if(userData?.roles.includes("Admin")  || userData?.roles.includes("SuperAdmin")){
          let res = await axiosInstance.get<BillData[]>(`/Bills/GetBillsByStoreClient?cellphone=${phone}`);
            let arrayFilter = res.data.filter((x: BillData) => x.storeClient.cellphone == phone).map((x:any) =>{
                let el:any = {}
                el.guia = x.guia;
                el.created_at = x.created_at;
                el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                el.status = x.status === 'Impreso-G' || x.status === 'Impreso-R' ? 'Despachado' : x.status;
                el.delivery_value = `$ ${x.delivery_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                el.company_TransportId = x.company_TransportId

                let arrayProducts: string[] = x.bill_Products.map((prod: BillProducts) => {
                  return ` ${prod.quantity} x ${productsList.filter((p: product) => p.id === prod.productId)[0].name}`;
                });
                el.productos = arrayProducts.join();

                if(x.status !== 'Anulado' && x.status !== 'Devuelto'){
                  el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                  el.collect_value = `$ ${0}`;
                }

                let valueProducts: number[] = x.bill_Products.map((prod: BillProducts) => {
                    return (prod.sellingPrice ? prod.sellingPrice : 0) * prod.quantity;
                  })
                el.value = `$ ${valueProducts.map((item: number) => item).reduce((prev: number, curr: number) => prev + curr, 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                return el;
            } )
            setBillList(arrayFilter);
            setLoading(false);
        } else {
          let res = await axiosInstance.get<BillData[]>(`/Bills/GetBillsByStoreClient?cellphone=${phone}`);
            setLoading(true);
            if ( localStorage.storeID !== "" ) {
              let arrayFilter = res.data.filter((x: BillData) => x.storeId == localStorage.storeID && x.storeClient.cellphone == phone).map((x:any) =>{
                  let el:any = {}
                  el.guia = x.guia;
                  el.created_at = x.created_at;
                  el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  el.status = x.status === 'Impreso-G' || x.status === 'Impreso-R' ? 'Despachado' : x.status;
                  el.delivery_value = `$ ${x.delivery_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  el.company_TransportId = x.company_TransportId;
                
                  let arrayProducts: string[] = x.bill_Products.map((prod: BillProducts) => {
                    return ` ${prod.quantity} x ${productsList.filter((p: product) => p.id === prod.productId)[0].name}`;
                  });
                  el.productos = arrayProducts.join();

                  if(x.status !== 'Anulado' && x.status !== 'Devuelto'){
                    el.collect_value = `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  } else {
                    el.collect_value = `$ ${0}`;
                  }
  
                  let valueProducts: number[] = x.bill_Products.map((prod: BillProducts) => {
                      return (prod.sellingPrice ? prod.sellingPrice : 0) * prod.quantity;
                    })
                  el.value = `$ ${valueProducts.map((item: number) => item).reduce((prev: number, curr: number) => prev + curr, 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        
                  return el;
              } )
              
              setBillList(arrayFilter);
              setLoading(false);
            }
        }
    }

    // Buscador de las guias
    const findGuia = (guia: string) => {
      setGuia(guia)
      setModalSearchGuiaVisible(true);
    }

    const getProducts = async () => {
      let res = await axiosInstance.get<product[]>("/Products");
        setProductsList(res.data);
    }

    function cellRender(data:any) {
      if (data.value === 1) {
        return 'Envia';
      }
      if (data.value === 2) {
        return 'Paloma';
      }
      if (data.value === 3) {
        return 'Interapidisimo';
      }
      if (data.value === 4) {
        return 'Crecel';
      }
    }

    return {
        loading,
        SearchBill,
        billList,
        findGuia,
        guia,
        modalSearchGuiaVisible,
        setModalSearchGuiaVisible,
        cellRender,
        productsList,
        showModal,
        modalVisible, 
        setModalVisible
    }
}
