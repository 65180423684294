import { message } from "antd";
import { axiosInstance } from "../../commons/services/axiosConfig";



export function loadStores() {
  return axiosInstance.get(`Stores/GetStoreList`)
    .then((response) => {
      return response.data
    })
    .catch((error) => message.error(error.message));
}

export function loadProducts(mentorStores: string[]) {
  return axiosInstance.post("StoreProducts/storesandproduct?Currency=COP", mentorStores)
    .then((response) => {
      return response.data
    })
    .catch((error) => message.error(error.message));
}

export function addStoreProducts(storeProducts: any[]) {
  return axiosInstance.post("StoreProducts/storesandproduct?Currency=COP", storeProducts)
    .then((response) => {
      return response.data;
    })
    .catch((error) => message.error(error.message));
}


export function addUser(user: any) {
  return axiosInstance.post("Users", user)
    .then(() => message.success("Usuario agregado exitosamente"))
    .catch((error) =>
      message.error(`Error agregando usuario. Error:${error.message}`)
    );
};

export function editUser(user: any) {
  return axiosInstance.put(`Users/${user.id}`, user)
    .then(() => message.success("Usuario editado exitosamente"))
    .catch((error) =>
      message.error(`Error editando usuario. Error:${error.message}`)
    );
};

export function searchUser(email: string) {
  return axiosInstance.get(`Users/GetUserByEmail/${email}`)
    .then((resp) => {
      return resp.data
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        message.error(`Usuario con el correo electrónico '${email}' no encontrado.`);

      } else {
        // Otro tipo de error, muestra un mensaje genérico
        message.error(`Error buscando usuario. Error:${error.message}`);
      }
      return null;
    })
};

export function getGroups() {
  return axiosInstance.get(`Users/GetGroups`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => message.error(error.message));
}

export function putConfigGroup(config: any) {
  return axiosInstance.put(`products/CreateConfigGroup/${config.name}`, config.storeProducts)
    .then(() => message.success("Configuración guardada exitosamente"))
    .catch((error) =>
      message.error(`Error guardando configuración. Error:${error.message}`)
    );
}

export function getConfigGroup(group: string): any {
  return axiosInstance.get(`products/configGroup/${group}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      message.error(`Error buscando configuracion. Error:${error.message}`);
    })
};

