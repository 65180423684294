import './FillContract.css'
import { useContext, useEffect, useState } from "react";
import FranquiciaContract from './Fraquicia';
import { PageContext } from '../../Hooks/ContextProvider';
import { axiosInstance } from '../../commons/services/axiosConfig';
import { storeModel } from '../../Models/store';
import Header from '../Header/Header';
import DropHackingContract from './DropHacking';

export interface IContract {
    id: number;
    name: string;
    description: string;
    urlFile: string;
}

interface IContractStore {
    id: string;
    status: number;
    contract: IContract;
    dateUpdated: Date;
    urlDocument: string;
}

const FillContract = () => {

    const [contractFranquicia, setContractFranquicia]: any = useState<IContractStore>();
    const [contractDropHacking, setContractDropHacking]: any = useState<IContractStore>();

    const {
        userData,
    } = useContext(PageContext);


    useEffect(() => {

        if (userData) {
            debugger
            axiosInstance.get<storeModel>(`/Stores/${userData.storeID}`).then(async res => {
                let franquicia = await res.data.contractStores.find(x => x.contract.name === "Franquicia CRECEL");
                let Drop = await res.data.contractStores.find(x => x.contract.name === "DropHaking Mind");
                if (franquicia?.status === 0) {
                    setContractFranquicia(res.data.contractStores.find(x => x.contract.name === "Franquicia CRECEL"))
                }
                if (Drop?.status === 0) {
                    setContractDropHacking(res.data.contractStores.find(x => x.contract.name === "DropHaking Mind"))
                }
            }
            )
        }
    }, []);

    return (
        <div>
            <Header></Header>
            {contractFranquicia &&
                <FranquiciaContract id={contractFranquicia.id}></FranquiciaContract>}

            {contractDropHacking && !contractFranquicia &&
                <DropHackingContract id={contractDropHacking.id}></DropHackingContract>}

        </div>
    )
}

export default FillContract