import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { storeWalletMovementFast } from "../../Models/store_wallet.model";
import { PageContext } from "../ContextProvider";
import { storeModel } from "../../Models/store";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Popconfirm, notification } from 'antd';

export const useStoreWalletManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [historyMovementDetail, setHistoryMovementDetail] = useState<
    storeWalletMovementFast[]
  >([]);
  const [storeList, setStoreList] = useState<storeModel[]>([]);
  const [currency, setCurrency] = useState<"COP" | "USD">("COP");
  const [selectedStore, setSelectedStore] = useState<string>("");
  const [totalWallet, setTotalWallet] = useState(0);
  const [Adelanto, setAdelanto] = useState(0);

  const { userData } = useContext(PageContext);

  useEffect(() => {
    getStoreList();
    getStoreWalletHistory();
  }, [selectedStore, currency]);

  function valueCustom({ data }: any) {
    if (data.value > 0) {
      return (
        <span style={{ color: "green" }}>
          <CaretUpOutlined />{" "}
          {`${data.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      );
    }
    if (data.value < 0) {
      return (
        <span style={{ color: "red" }}>
          <CaretDownOutlined />{" "}
          {`${data.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      );
    }
    if (data.shippingTotal) {
      return (
        <span style={{ color: "red" }}>
          <CaretDownOutlined />{" "}
          {`${data.shippingTotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      );
    }
  }

  const getStoreList = async () => {
    let res = await axiosInstance.get("/Stores/GetStoreList");
    setStoreList(res.data);
  };

  const getStoreWalletHistory = async () => {
    if (selectedStore) {
      setLoading(true);
      let res = await axiosInstance.get(
        `/StoreWallet/${
          currency == "COP" ? "GetStoreWallets" : "GetUSDStoreWallets"
        }?storeId=${selectedStore.split("-")[0]}`
      );
      res.data.map((item: any) => {
        item.created_at = moment(item.created_at)
          .utcOffset("GMT-05")
          .format("YYYY-MM-DD HH:mm");
      });
      setHistoryMovementDetail(res.data);
      getTotalWallet(parseInt(selectedStore.split("-")[0]))
      setLoading(false);
    }
  };

  const getTotalWallet = async (storeId:number) => {
      let storeWallet = await axiosInstance.get(`/StoreWallet/TotalPurse?storeId=${storeId}&currency=${currency}`);
      let availableAdvance =  await axiosInstance.get(`/StoreWallet/TotalAvailableAdvance?storeId=${storeId}&currency=${currency}`);
      setAdelanto(availableAdvance.data);
      setTotalWallet(storeWallet.data);
  }

  const DeleteMovement = async (movementId:number,storeId:number) => {
    setLoading(true);
    let resp = await axiosInstance.get(`/StoreWallet/CancelWalletMovement?MovementId=${movementId}&StoreId=${storeId}&Currency=${currency}`);
    if(resp.status == 200)
    {
        getStoreWalletHistory();
        notification.success({message:'El movimiento fue anulado'})
        setLoading(false);
    }
    else
    {
        notification.error({message:resp.statusText})
        setLoading(false);
    }
  }

  const cellRenderDeleteMovement = (row: any) => {
    return (
      <Popconfirm
        title="¿Está seguro de anular este movimiento?"
        onConfirm={() => DeleteMovement(row.data.id,row.data.storeId)}
      >
        <StopOutlined style={{color:'red'}}/>
      </Popconfirm>
    );
  };


  return {
    loading,
    historyMovementDetail,
    valueCustom,
    storeList,
    setCurrency,
    currency,
    setSelectedStore,
    totalWallet,
    Adelanto,
    cellRenderDeleteMovement
  };
};
