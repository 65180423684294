import React, { useState } from 'react';

// ANT
import { Col, Input, InputNumber, Row, Select, Tabs, Switch, Card, Spin, Form, message, Button } from 'antd';
import { axiosInstance } from '../../../commons/services/axiosConfig';
import { productImages } from '../../../Models/Products';
import { Center } from 'devextreme-react/map';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import { color } from 'html2canvas/dist/types/css/types/color';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const { TextArea } = Input;
const { Option } = Select;

type ProductProps = {
    product: Product,
    setProduct: Function,
}
const General = ({ product, setProduct }: ProductProps) => {

    const [loading, setLoading] = useState(false);

    const handleChange = async ({ target }: any) => {
        const { name, value } = target;

        if (name === 'image') {
            const file = target.files[0];
            let formData = new FormData();

            const myNewFile = new File([file], `${product.id}.${file.type}`, { type: file.type });
            formData.append('Attachments', myNewFile);
            formData.append('strContainerName', `product-images`);
            formData.append('folderName', `product-images`);

            const urlImage = await axiosInstance.post<productImages>(`/BlobStorage/UploadImageGallery`, formData)
            message.success('Se ha cargado la imagen correctamente');
            const imagen = urlImage.data;

            setProduct({
                ...product,
                [name]: imagen
            })
            return;
        }
        setProduct({
            ...product,
            [name]: value
        })
    }

    const handleOtherChange = (value: any, name: string) => {
        setProduct({
            ...product,
            [name]: value
        })
    }

    return (
        <div>
            <Form>
                <Row style={{ marginTop: 5 , display: 'flex' , justifyContent: 'center'}}>
                    <div style={{ width: '200px', height: '30px' }}>
                        <b>Activo en COLOMBIA</b> &nbsp;
                            <Switch
                                size="small"
                                checked={product.isActive}
                                onChange={(e) => handleOtherChange(e, 'isActive')}
                             />
                    </div>
                
                    <div style={{ width: '200px', height: '30px' }}>
                        <b>Activo en ECUADOR</b> &nbsp;
                            <Switch
                                 size="small"
                                 checked={product.isActiveEcuador}
                                onChange={(e) => handleOtherChange(e, 'isActiveEcuador')}
                                />
                    </div>
                </Row>
                <Row>
                    <Col span={12}>
                        <Row style={{ marginTop: 10 }}>
                            <Col span={23}>
                                <b>NOMBRE:</b>
                                <Input
                                    style={{ width: '94%' }}
                                    value={product.name}
                                    onChange={handleChange}
                                    name="name"
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col span={24}>
                                <b>DESCRIPCIÓN:</b>
                                <TextArea rows={10}
                                    style={{ width: '90%' }}
                                    value={product.description}
                                    onChange={handleChange}
                                    name="description"
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={24} style={{ marginTop: '10px'}}>
                                <Button style={{  height: '40px', width: '250px', fontSize: '20px', 
                                    background: '#1890ff', color: 'white',
                                    fontWeight:'bold', pointerEvents:'none', marginLeft:'180px'}}>
                                    Colombia
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', width:'94%' }} >
                            <Col span={5}>
                                <b>COSTO:</b>
                                <InputNumber
                                    value={product.cost}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'cost')}
                                />
                            </Col>
                            <Col span={6}>
                                <b>PRECIO DE VENTA:</b>
                                <InputNumber
                                    value={product.selling_price}
                                    style={{ width: '90%', marginLeft:'1px' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'selling_price')}
                                />
                            </Col>
                            <Col span={6}>
                                <b>PRECIO SUGERIDO:</b>
                                <InputNumber
                                    value={product.suggested_selling_price}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'suggested_selling_price')}
                                />
                            </Col>
                            <Col span={7}>
                                <b>P. MÍNIMO DE VENTA:</b>
                                <InputNumber
                                    value={product.max_Selling_price}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'max_Selling_price')}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10, width:'90%'}}>
                            <Col span={8}>
                                <b>COMISIÓN 1:</b>
                                <InputNumber
                                    value={product.commission_value1}
                                    style={{ width: '70%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'commission_value1')}
                                />
                            </Col>
                            <Col span={8}>
                                <b>COMISIÓN 2:</b>
                                <InputNumber
                                    value={product.commission_value2}
                                    style={{ width: '70%',  }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'commission_value2')}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }} gutter={[16, 16]}>
                            {/*<Col span={14} >
                                <b>PRODUCTO GRATIS</b> &nbsp;
                                <Switch
                                    size="small"
                                    checked={product.isFree}
                                    onChange={(e) => handleOtherChange(e, 'isFree')}
                                />
                                {product.isFree ? 'PRODUCTO GRATUITO' : 'PRODUCTO NO GRATUITO'}
                            </Col>*/}
                        </Row>
                    </Col>
                    
                    <Col span={12}>
                    <Row style={{ marginTop: 10 }}></Row>
                        IMAGEN:
                        <Col span={24}>
                            {/* <Upload {...propsUploadProductImage}>
                                <Button icon={<UploadOutlined />}>Cargar</Button>
                            </Upload> */}
                            <Input type="file" name="image" onChange={handleChange}  />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {product.image !== "" &&
                                <Card style={{}}>
                                    <Spin tip='Cargando imagen...' spinning={loading}>
                                        <img src={product.image} style={{ width: '100%', maxHeight: 200, }} alt="icono menú"/>
                                    </Spin>
                                </Card>
                            }
                        </Col>

                        <Row style={{ marginTop: '10px' }}>
                            <Col span={24} style={{ marginTop: '10px'}}>
                                <Button style={{  height: '40px', width: '250px', fontSize: '20px', 
                                     background: '#1890ff', color: 'white',
                                    fontWeight:'bold', pointerEvents:'none', marginLeft:'180px'}}>
                                    Ecuador
                                </Button>
                            </Col>
                        </Row>
                    
                        <Row style= {{ marginTop: '15px'}} >
                            <Col span={4}>
                                <b>COSTO USD:</b>
                                <InputNumber
                                    value={product.usD_Cost}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'usD_cost')}
                                />
                            </Col>
                            <Col span={7}>
                                <b>PRECIO DE VENTA USD:</b>
                                <InputNumber
                                    value={product.usD_Selling_price}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'usD_Selling_price')}
                                />
                            </Col>
                            <Col span={6}>
                                <b>P. SUGERIDO USD:</b>
                                <InputNumber
                                    value={product.usD_Suggested_selling_price}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'usD_Suggested_selling_price')}
                                />
                            </Col>
                            <Col span={7}>
                                <b>P. MÍN. DE VENTA USD:</b>
                                <InputNumber
                                    value={product.usD_Max_Selling_price}
                                    style={{ width: '90%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'usD_Max_Selling_price')}
                                />
                            </Col>
                        </Row>
                    
                        <Row style={{ marginTop: 10, }} >
                            <Col span={8}>
                                <b>COMISIÓN USD 1:</b>
                                <InputNumber
                                    value={product.usD_Commission_value1}
                                    style={{ width: '70%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'usD_Commission_value1')}
                                />
                            </Col>
                            <Col span={8}>
                                <b>COMISIÓN USD 2:</b>
                                <InputNumber
                                    value={product.usD_Commission_value2}
                                    style={{ width: '70%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => handleOtherChange(e, 'usD_Commission_value2')}
                                />
                            </Col>
                        </Row>
                        </Col>
                    <Row style={{ marginTop: '30px', marginLeft: '130px', alignItems: 'center' , justifyContent: 'center', width:'80%'}}>
                        <Col span={5}>
                                <b>PROVEEDOR:</b>
                                <Input
                                    style={{ width: '90%' }}
                                    value={product.provider}
                                    onChange={handleChange}
                                    name="provider"
                                />
                        </Col>
                        <Col span={6}>
                                <b>CATEGORÍA:</b>
                                <Select
                                    style={{ width: '90%' }}
                                    value={product.category_id}
                                    onChange={(e) => handleOtherChange(e, 'category_id')}
                                >
                                    <Option value={1}>BELLEZA</Option>
                                    <Option value={2}>CUIDADO PERSONAL</Option>
                                    <Option value={3}>FITNESS</Option>
                                    <Option value={4}>OTROS</Option>
                                </Select>
                        </Col>
                        <Col span={4}>
                            <b>PESO:</b>
                                <InputNumber
                                    value={product.weight}
                                    style={{ width: '90%' }}
                                    onChange={(e) => handleOtherChange(e, 'weight')}
                            />
                        </Col>
                        
                        <Col span={5}>
                            <b>DÍAS DE GARANTÍA:</b>
                                <InputNumber
                                    value={product.warranty_days}
                                    style={{ width: '90%' }}
                                    onChange={(e) => handleOtherChange(e, 'warranty_days')}
                                />
                        </Col>
                 </Row>
             </Row>
        </Form>
     </div>
    )
}

export default General

type Product = {
    id?: number,
    name?: string,
    image?: string,
    description?: string,
    cost?: number,
    selling_price?: number,
    suggested_selling_price?: number,
    provider?: string,
    stock?: number,
    warranty_days?: number,
    youtube?: string,
    commission_value1?: number,
    commission_value2?: number,
    category_id?: number,
    max_Selling_price?: number,
    promotional_Images?: string,
    url_Post?: string,
    url_Folder_Images?: string,
    url_Folder_Videos?: string,
    url_Folder_Segmentos?: string,
    url_Landing?: string,
    url_Questions?: string,
    text_Copy?: string,
    testimonials?: string,
    total_Likes?: number,
    weight?: number,
    madeInOwnLab?: boolean,
    isFree?: boolean,
    isActive?: boolean,
    usD_Cost?: number,
    usD_Selling_price?: number,
    usD_Suggested_selling_price?: number,
    usD_Commission_value1?: number,
    usD_Commission_value2?: number,
    usD_Max_Selling_price?: number,
    isActiveEcuador?: boolean
}
