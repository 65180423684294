import { message } from "antd";
import { axiosInstance } from "../../commons/services/axiosConfig";
import { useState } from "react"

export const useTableCommission = () => {
    const [products, setProducts] = useState<any[]>([]);

    function loadProducts(email: string) {
        axiosInstance.get(`Products/commission/${email}`)
            .then((response) => {
                setProducts(response.data);
            })
            .catch((error) => message.error(error.message));
    }

    function updateCommission(mentoStoreProd: any) {
        axiosInstance.put(`Products/commission`, mentoStoreProd)
            .then(() => message.success("Comisión actualizada"))
            .catch((error) =>
                message.error(`Error actualizando comisión. Error:${error.message}`))
    }

    return {
        loadProducts,
        products,
        updateCommission
    }


}